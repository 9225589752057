import { useState } from "react";
import { Alert, Avatar, Button, Rating, Snackbar, TextField } from "@mui/material";
import { getLocalStorage, getLocalStorageJWT } from "../../Context/Storage";
import { styled } from "@mui/material/styles";
import { guardarProductoComentario } from "../Funciones/Productos";


import Reseña from "./Reseña";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#c9a396",
  },
  "& .MuiRating-iconHover": {
    color: "#ff3d47",
  },
});

export default function DescripcionReserñas({
  descipcion
  , resenas
  , setReseñas
  , id_producto
  , total_resenas
  , setShowDialogReseña
  , hideInputComentario = false
  , hideDescripcion = false
  , id_cliente
  , setShowDialogReseñaEditar
  , setComentarioEditar
}) {

  const IMG_PERFIL = getLocalStorage("img")

  const initComentario = {
    id: 0,
    comentario: "",
    id_producto: id_producto,
    calificacion: 0,
  }
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [comentario, setComentario] = useState(initComentario);

  const GuardarComentario = () => {
    new Swal({
      title: "¿Estás seguro de agregar una reseña a este producto?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#3ABE88",
      denyButtonColor: "#65748B",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        guardarProductoComentario(comentario.id_producto, comentario.comentario, comentario.calificacion)
          .then((resp) => {

            if (resp.data.codigo == "200") {
              MySwal.fire({
                title: "Correcto",
                html: resp.data.mensaje,
                icon: "success",
                confirmButtoColor: "#3ABE88",
                showConfirmButton: false,
                timer: 1700,
              }).then((result) => {
                const datos = JSON.parse(resp.data.respuesta)
                setReseñas([...resenas, {
                  CLIENTE: datos.cliente,
                  ID_CLIENTE: datos.id_cliente,
                  FECHA: datos.fecha,
                  ID: datos.id,
                  COMENTARIO: comentario.comentario.replaceAll("\n", "<br>"),
                  CALIFICACION: comentario.calificacion
                }])
                setComentario({ ...initComentario })
              });
            } else {
              setMensaje(resp.data.mensaje);
              setOpen(true);
            }
          })
          .catch((data) => {
            // alert(data.mensaje);
            setMensaje(data.mensaje);
            setOpen(true);
          });
      }
    });
  }



  return (

    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <div className={(hideDescripcion ? '' : 'mt-5 pt-2')}>
        <div className="row">
          {
            !hideDescripcion && (
              <div className="col-12">
                <div className="container-descriptivo p-2">
                  <h6 className="font-OxygenMedium text-star">Descripción</h6>
                </div>
                <div className="p-2" 
                dangerouslySetInnerHTML={{
                   __html: descipcion.replace(/(?:\r\n|\r|\n)/g, '<br/>') }} />

              </div>
            )
          }


          <div className={"col-12" + (hideDescripcion ? '' : ' mt-5 ')}>

            {
              !hideDescripcion && (
                <div className="container-descriptivo p-2">
                  <h6 className="font-OxygenMedium text-star">
                    Reseñas ({ total_resenas })
                  </h6>
                </div>
              )
            }



            <div className=" p-2">
              {
                resenas && Array.isArray(resenas) && (
                  resenas.map((row) => (
                    <Reseña
                      row={row}
                      id_cliente={id_cliente}
                      setShowDialogReseñaEditar={setShowDialogReseñaEditar}
                      setComentarioEditar={setComentarioEditar}
                    />
                  ))
                )
              }

              {
                total_resenas > 10 && !hideInputComentario && (
                  <Button
                    onClick={() => setShowDialogReseña(true)}
                    loadingPosition="start"
                    className="btn bg-white text-info text-left mt-3 font-OxygenBold w-100 py-2 px-4 "
                    variant="outline"
                  >
                    <span>Mostrar más comentarios</span>
                  </Button>
                )
              }

              {
                getLocalStorageJWT() != "" && !hideInputComentario && (
                  <div className="d-flex justify-content-between flex-wrap mt-5 pe-2">
                    <Avatar
                      className="shadow mt-2 ms-2"
                      sx={{ width: 60, height: 60 }}
                      src={IMG_PERFIL}
                    />
                    <div style={{ width: 'calc(100% - 90px)' }}>
                      <div className="col-12   ">
                        <TextField
                          onChange={({ target }) => {
                            setComentario({ ...comentario, comentario: target.value });
                          }}
                          value={comentario.comentario}
                          fullWidth
                          multiline={true}
                          maxRows={3}
                          minRows={3}
                          size="small"
                          name="envia"
                          label="Deja un comentario"
                          placeholder="(Máx 300)"
                          variant="outlined"
                          inputProps={{ maxLength: 300 }}
                        />
                      </div>





                      <div className="d-flex justify-content-between w-100 mt-2">
                        <StyledRating
                          onChange={({ target }) => {
                            setComentario({ ...comentario, calificacion: target.value });
                          }}
                          name="customized-color"
                          defaultValue={0}
                          precision={1}
                          size="small"
                          value={comentario.calificacion}
                        />


                        <Button
                          onClick={GuardarComentario}
                          loadingPosition="start"
                          className="btn btn-michelle  font-OxygenBold  py-2 px-4 "
                          variant="contained"
                        >
                          <span>Enviar</span>
                        </Button>

                      </div>


                    </div>
                  </div>
                )
              }

            </div>
          </div>

        </div>

      </div>
    </>
  );
}
