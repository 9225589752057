import loogo from "./../assets/FooterBlanco.svg";
import Instagram from "./../assets/Instagram.svg";
import Facebook from "./../assets/Facebook.svg";
import pinterest from "./../assets/pinterest.png";
import youtube from "./../assets/youtube.png";

const Footer = () => {
  return (
    <>
      <div className="w-100 bg-dark py-4">
        <div className="container-xxl  pb-5 pb-md-1 ">
          <div className="row p-3">
            <div className="col-12 col-md-6">
              <img src={loogo} className="img-fluid" />
            </div>
            <div className="col-12 col-md-6">
              <div className="row mt-5 mt-md-0">
                <div className="col-12 col-md-6">
                  <h5 className="text-white font-Oxygen">Contáctanos</h5>
                  <br />
                  <a
                    href="mailto:ventas.mpourroy@gmail.com"
                    style={{ textDecoration: "none" }}
                  >
                    <h6 className="text-white font-Oxygen">
                      ventas.mpourroy@gmail.com
                    </h6>
                  </a>
                  <h6 className="text-white font-Oxygen">332 343 7579</h6>
                </div>
                <div className="col-12 col-md-6 mt-5 mt-md-0 ps-2 ps-md-4">
                  <h5 className="text-white font-Oxygen">Síguenos en </h5>
                  <br />
                  <div className="d-flex">
                    <div>
                      <a
                        href="https://www.instagram.com/michellepourroy/?hl=es"
                        target="_blank"
                      >
                        <img src={Instagram} className="img-fluid me-3" />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.facebook.com/EntreFlorByMichelle/?locale=es_LA"
                        target="_blank"
                      >
                        <img src={Facebook} className="img-fluid me-3" />
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.youtube.com/@michellepourroy"
                        target="_blank"
                      >
                        <img
                          src={youtube}
                          className="img-fluid me-3"
                          style={{ width: "26px" }}
                        />
                      </a>
                    </div>

                    <div>
                      <a
                        href="https://www.pinterest.com.mx/michellepourroy/"
                        target="_blank"
                      >
                        <img
                          src={pinterest}
                          className="img-fluid me-3"
                          style={{ width: "28px" }}
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div className="d-flex justify-content-star flex-wrap flex-column flex-md-row">
                <div className="me-4">
                  <p className="text-white my-0" style={{ fontSize: "12px" }}>
                    Michelle Pourroy 2023. Todos los derechos reservados
                  </p>
                </div>

                <div className="pe-0 pe-md-3 my-5 my-md-0 border-end">
                  <a
                    href="/politica-privacidad"
                    style={{ textDecoration: "none" }}
                  >
                    <p className="text-white my-0" style={{ fontSize: "12px" }}>
                      Aviso de privacidad
                    </p>
                  </a>
                </div>
                <div className="ps-0 ps-md-3">
                  <p className="text-white my-0" style={{ fontSize: "12px" }}>
                    Términos y condiciones
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
