import { getLocalStorageJWT, clearStorageJWT } from "./../Context/Storage";
import React, { useState, useEffect } from "react";

export const SERVER_IMG =
  "https://florcreativa.blob.core.windows.net/imagenes/";

const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? "https://emitefacturas.com/flowers/api/"
    : "http://localhost:7053/api/";

export const KEY = "27a7f6f3-f9fe-421f-ab72-fdcbf751ff97";
export const KEY_KONECTA =
  process.env.NODE_ENV === "production"
    ? "key_Yl0dYc5lwmXXABRBkpEzQuF" // pepe key_5DR2TQm9HMyI5bgtZfwg9Sd
    : "key_JxynMcleoavPPe7wzEG7noQ"; // flexor pruebas

export const KEY_STRIPE =
  process.env.NODE_ENV === "production"
    ? "pk_test_51JjnctJpby5qGDzYCX7bHgZgldHKjOG3f5ekkd8MWJOFPk6fjTRWcpOQhX6dRjEUwTsZvRz7VomvassmzDIbkoD900zNmqQD1w" // pepe key_5DR2TQm9HMyI5bgtZfwg9Sd
    : "pk_test_51JjnctJpby5qGDzYCX7bHgZgldHKjOG3f5ekkd8MWJOFPk6fjTRWcpOQhX6dRjEUwTsZvRz7VomvassmzDIbkoD900zNmqQD1w"; // flexor pruebas
    

export function postdData(url, body) {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      //console.log(response);
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export function postUrl(url) {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + bearer_token,
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        return {
          error: true,
          mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
        };
      }
    })
    .catch((error) => {
      console.log(error);
      return { error: true, mensaje: error };
    });
}

export const getData = (url, useJWT = true) => {
  let bearer_token = getLocalStorageJWT();
  return fetch(BACKEND_URL + url, {
    method: "GET",
    headers: {
      Authorization: useJWT ? "Bearer " + bearer_token : "Basic",
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (response.status == 401) {
        clearStorageJWT();
      }
      return response.json();
    })
    .then((response) => {
      if (response.resp.codigo == "200") {
        return { error: false, data: response.resp };
      } else {
        if (response.resp.codigo == "403") {
          clearStorageJWT();
        } else {
          return {
            error: true,
            mensaje: response.resp.mensaje + " (" + response.resp.codigo + ")",
          };
        }
      }
    })
    .catch((error) => {
      return { error: true, mensaje: error };
    });
};
export default BACKEND_URL;
