import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
  TextareaAutosize,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";

import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import Loading from "../../../Includes/Loading";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { EnviarCorreo } from "../../Funciones/Bride";
import { BasicDatePicker } from "../Picker/DatePicker";
import { BasicTimeClock } from "../Picker/TimePicker";
import {
  ConvertDate,
  GetDate,
} from "../../../Lib/Funciones Generales/ObtenerFecha";
import { format } from "date-fns";

const MySwal = withReactContent(Swal);

const ModalAgendar = ({ SetshowModalAgendar, id, getTarjetas }) => {
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [token, setToken] = useState("");
  const [Fecha, setFecha] = useState(GetDate());

  const [Hora, setHora] = useState(Fecha);

  const [Values, setValues] = useState({
    nombre: "",
    apellidos: "",
    correo: "",
    fecha: "",
    hora: "",
    mensaje: "",
  });
  const [errores, setErrores] = useState({
    nombre: false,
    apellidos: false,
    correo: false,
    fecha: false,
    hora: false,
    mensaje: false,
  });

  function handlInputChange({ target, buscador }) {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...errores,
      [Name]: false,
    });
  }

  const Enviar = () => {
    setIsGuardando(true);
    //Formato fecha
    const date = new Date(Fecha);
    const FechaFormato = format(date, "dd/MM/yyyy");
    //formato hora
    const date_ = new Date(Hora);
    const HoraFormato = format(date_, "HH:mm");
    //console.log(HoraFormato);

    EnviarCorreo(
      Values,
      FechaFormato,
      HoraFormato,
      errores,
      setErrores,
      setToken
    )
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#C9A396",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setIsGuardando(false);
            SetshowModalAgendar(false);
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      <Dialog fullWidth maxWidth="sm" open={true}>
        <IconButton
          aria-label="Close"
          className="m-3"
          style={{ position: "absolute", right: "0%" }}
          onClick={() => {
            SetshowModalAgendar(false);
          }}
        >
          <CloseIcon size="lg" />
        </IconButton>
        <DialogTitle>
          <h6 className="fw-bolder f-12 mt-2">Agenda una cita</h6>
        </DialogTitle>
        <DialogContent>
          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (
            <Row>
              <Col sm={6} className="p-2">
                <TextField
                  fullWidth
                  name="nombre"
                  label="Nombre"
                  variant="outlined"
                  placeholder="Nombre "
                  value={Values.nombre}
                  error={errores.nombre}
                  helperText={errores.nombre && "Ingresa el nombre"}
                  onChange={handlInputChange}
                />
              </Col>

              <Col sm={6} className="p-2">
                <TextField
                  fullWidth
                  name="apellidos"
                  label="Apellidos"
                  variant="outlined"
                  placeholder="Apellidos"
                  helperText={errores.apellidos && "Ingresa apellidos"}
                  value={Values.apellidos}
                  error={errores.apellidos}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={6} className="p-2">
                <TextField
                  fullWidth
                  name="correo"
                  type="correo"
                  label="Correo electrónico"
                  variant="outlined"
                  placeholder="Correo electrónico"
                  helperText={
                    errores.correo && "Correo electrónico es inválido"
                  }
                  value={Values.correo}
                  error={errores.correo}
                  onChange={handlInputChange}
                />
              </Col>
              <Col sm={6} className="p-2">
                <TextField
                  fullWidth
                  name="telefono"
                  type="number"
                  label="Teléfono"
                  variant="outlined"
                  placeholder="Teléfono"
                  helperText={errores.telefono && "El teléfono es inválido"}
                  value={Values.telefono}
                  error={errores.telefono}
                  onChange={handlInputChange}
                  maxLength="10"
                />
              </Col>

              <Col sm={6} className="p-2">
                <BasicDatePicker
                  name="fecha"
                  fecha={Fecha}
                  format="DD/MM/YYYY"
                  label="Fecha"
                  setFecha={setFecha}
                />
              </Col>

              <Col sm={6} className="p-2">
                <BasicTimeClock
                  fullWidth
                  name="hora"
                  hora={Hora}
                  helperText={errores.hora && "Elige una hora"}
                  setHora={setHora}
                  error={errores.mensaje}
                />
              </Col>

              <Col sm={12} className="p-2">
                <TextField
                  fullWidth
                  name="mensaje"
                  label="Mensaje"
                  variant="outlined"
                  placeholder="Escribe un mensaje"
                  helperText={errores.telefono && "Ingresa mensaje"}
                  value={Values.mensaje}
                  error={errores.mensaje}
                  onChange={handlInputChange}
                  multiline
                  rows={5}
                ></TextField>
              </Col>
            </Row>
          )}
        </DialogContent>
        <DialogActions>
          <Col sm={12} className="p-3">
            <LoadingButton
              loading={IsGuardando}
              loadingPosition="start"
              disabled={IsLoading}
              onClick={() => Enviar()}
              className="btn shadow-none w-100 btn-michelle font-OxygenMedium  py-2 px-4 "
              variant="contained"
              xs={{ with: "100$" }}
            >
              <span
                className={IsGuardando ? "px-4 " : "px-2 font-OxygenMedium "}
              >
                {IsGuardando ? "Validando..." : "Enviar"}
              </span>
            </LoadingButton>
          </Col>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ModalAgendar;
