import {
  Alert,
  Avatar,
  Button,
  ButtonBase,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";

import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import ModalTarjeta from "./ModalTarjeta";
import {
  Eliminar,
  getLista,
  seDefault,
} from "../../Funciones/Tarjetas";
import AccEditar from "./../../../assets/AccEditar.svg";
import AccEliminar from "./../../../assets/AccEliminar.svg";

const MySwal = withReactContent(Swal);

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const MisTarjetas = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalTarjeta, SetshowModalTarjeta] = useState(false);
  const [Lista, setLista] = useState([]);


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getTarjetas();
  }, []);

  const getTarjetas = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  const EliminarRegistro = (id, idTarjeta) => {
    new Swal({
      title: "¿Estás seguro de cambiar tu forma de pago preferida?",
      text: "esta acción no se puede deshacer",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {
      console.log(isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          Eliminar(id, idTarjeta)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Eliminado correctamente",
                icon: "success",
              }).then(function () {
                getTarjetas();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };


  const MarcarDefault = (id) => {
    new Swal({
      title: "¿Estás seguro se seleccionar esta tarjeta como predeterminada?",
      text: "Podrás cambiarla cuando quieras",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then(function (isConfirm) {


      if (isConfirm.isConfirmed) {
        if (id != undefined) {
          seDefault(id)
            .then((resp) => {
              new Swal({
                title: "Exito!",
                text: "Actualizado correctamente",
                icon: "success",
              }).then(function () {
                getTarjetas();
              });
            })
            .catch((resp) => {
              alert("error al eliminar");
            });
        }
      }
    });
  };



  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalTarjeta ? (
        <ModalTarjeta
          SetshowModalTarjeta={SetshowModalTarjeta}
          getTarjetas={getTarjetas}
        />
      ) : (
        ""
      )}

      <div style={{ maxWidth: "950px" }}>
        <Row className="p-3">
          <div className="col-12 text-end">
            <Button
              onClick={() => SetshowModalTarjeta(true)}
              className="btn shadow-none  btn-michelle font-OxygenMedium  py-2 px-4 "
              variant="contained"
              style={{ max: "400px" }}
            >
              <span className={"px-2 font-OxygenMedium"}>
                <AddIcon className="me-2" />
                Agregar
              </span>
            </Button>
          </div>

          {IsLoading ? (
            <Col sm={12} className="pt-5">
              <Loading />
            </Col>
          ) : (

            <div style={{ maxWidth: "950px" }}>
              <Row className="p-2">
                {Lista.map((row) => (
                  <>
                    <div
                      className="pb-0 px-3 px-md-4 pt-3  mt-3"
                      style={{ backgroundColor: "#F7F7F7" }}
                    >
                      <div className="row">
                        <div className="col-12 p-2">
                          <h6 className="font-OxygenBold">
                            {row.NOMBRE}
                          </h6>
                        </div>

                        <div className="col-12 d-flex justify-content-between mt-3 mt-md-1 mb-2">


                          <div className="p-2">
                            <p className="font-OxygenBold my-1">{row.TIPO}  **** **** **** {row.NUMERO}</p>
                          </div>
                          <div className="p-2">
                            <p className="font-OxygenBold my-1"> {row.VENCIMIENTO}</p>
                          </div>
                          <Button
                            className="btn-accion font-OxygenBold"
                            variant="text"
                            onClick={() => {
                              EliminarRegistro(row.ID, row.ID_TARJETA_CONEKTA);
                            }}
                          >
                            <img src={AccEliminar} className="me-2" /> Eliminar
                          </Button>
                        </div>

                      </div>
                    </div>
                    <FormControlLabel
                      onClick={() => {
                        if (!row.DEFAULT) {
                          MarcarDefault(row.ID);
                        }
                      }}
                      className="mb-2"
                      control={<Checkbox checked={row.DEFAULT} />}
                      label={
                        <Typography
                          className="font-Oxygen"
                          variant="body1"
                          component="span"
                          style={{ fontSize: "12px" }}
                        >
                          Tarjeta predeterminada
                        </Typography>
                      }
                    />

                  </>
                ))}
              </Row>
            </div>
          )}
        </Row>
      </div>
    </>
  );
};

export default MisTarjetas;
