import {
  Alert,
  Avatar,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { EnviarCorreo } from "../Funciones/Bride";

import ModalAgendar from "./Cita/MOdalAgendar";
import CarruselRandom from "../../Productos/Componentes/CarruselRandom";
import CarruselPopulares from "../../Productos/Componentes/CarruselPopulares";

const MySwal = withReactContent(Swal);
const Banner =
  "https://florcreativa.blob.core.windows.net/imagenes/BannerBride2.webp";
const ImgNovia =
  "https://florcreativa.blob.core.windows.net/imagenes/FotoBride.webp";
const ImgBride2 =
  "https://florcreativa.blob.core.windows.net/imagenes/FotoBride2.webp";
const ImgBride3 =
  "https://florcreativa.blob.core.windows.net/imagenes/FotoBride3.webp";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#ebe1d6",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#ebe1d6",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#ebe1d6",
    },
    "&:hover fieldset": {
      borderColor: "#ebe1d6",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#ebe1d6",
    },
    "& input": {
      color: "#ebe1d6",
    },
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  backgroundColor: "#d0a494",
  "&:hover": {
    backgroundColor: "#ebe1d6",
  },
  height: 50,
  width: 200,
  color: "#fff",
  fontWeight: "bold",
}));

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

function Bride() {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalAgendar, SetshowModalAgendar] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalAgendar ? (
        <ModalAgendar SetshowModalAgendar={SetshowModalAgendar} />
      ) : (
        ""
      )}

      <div className="espacio-header" />

      {/*<div
        className="w-100"
        style={{
          backgroundColor: "#0a0b0d",
        }}
      >
        <div
          className="container-fluid img-nosotros"
          style={{
            backgroundColor: "",
            backgroundImage: `url("${Banner}")`,
            height: "auto",
          }}
        >
          <div className="row">
            <div className="col-12 col-sm-7 col-md-6  p-3  mt-5 mt-md-0  d-flex justify-content-center align-items-center">
              <div className="d-flex flex-column align-items-start">
                <h4
                  className="font-Oxygen text-white"
                  style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}
                >
                  Nuestras
                </h4>
                <h4
                  className="font-PlayfairDisplayItalic text-white"
                  style={{ fontSize: "4rem" }}
                >
                  Amazing Brides
                </h4>
              </div>
            </div>

            <div className=" col-12 col-sm-5 col-md-6  p-0   d-flex  justify-content-end ">
              <div>
                <img src={Banner} className="img-fluid" />
              </div>
      </div>
          </div>
        </div>
      </div>*/}
      <div
        className="banner-bridge w-100"
        style={{
          backgroundImage: `url("${Banner}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {
          //aqui container clase abajo
        }
        <div
          className=" d-flex flex-wrap  align-items-center justify-content-start ps-5"
          style={{ height: 500, background: "" }}
        >
          <div className="flex-grow-2 text-center ps-5">
            <h4
              className="font-PlayfairDisplay text-white"
              style={{ fontSize: "3rem" }}
            >
              Bridal
            </h4>
            <h4
              className="font-PlayfairDisplay text-white"
              style={{ fontSize: "5rem" }}
            >
              blooms
            </h4>
          </div>
        </div>
      </div>
      <div className="w-100" style={{ backgroundColor: "#ebe1d6" }}>
        <div className="container">
          <div className="row" style={{ paddingTop: "6rem" }}>
            <div className="col-12 col-md-6">
              <div className="p-3 p-sm-0 p-md-3 pt-0  position-relative  ">
                <div
                  style={{
                    rotate: "-90deg",
                    position: "absolute",
                    left: "0px",
                    transformOrigin: "bottom left",
                    top: "122px",
                  }}
                >
                  <div
                    className="d-flex w-100 justify-content-center align-content-center align-items-center"
                    ata-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div>
                      <h6 className="font-PlayfairDisplay text-rosa text-nowrap">
                        (Brides)
                      </h6>
                    </div>
                    <div className="LineRosa ms-3" />
                  </div>
                </div>

                <p
                  className="font-PlayfairDisplay mt-0"
                  style={{ fontSize: "32px" }}
                >
                  Conoce todos nuestros <br /> ramos de novia
                </p>
                <p className="font-Oxygen mt-lg-4" style={{ fontSize: "20px" }}>
                  Nos encanta formar parte de los sueños de las personas.
                </p>

                <p className="font-Oxygen   " style={{ fontSize: "20px" }}>
                  Sabemos que es uno de los días más importantes de tu vida y
                  por eso te decimos GRACIAS por confiar en nosotros y dejarnos
                  ser parte del diseño floral. Tus ramos estarán hechos con todo
                  el amor y la pasión que nos caracteriza.
                </p>

                <div className=" my-4 py-4">
                  <ColorButton
                    variant="contained"
                    style={{ width: "200px" }}
                    onClick={() => SetshowModalAgendar(true)}
                  >
                    Agenda una cita
                  </ColorButton>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="d-flex justify-content-center align-content-end align-items-end h-100">
                <img
                  className="img-fluid mt-2 "
                  src={ImgNovia}
                  style={{ marginBottom: "-20%" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5 mt-md-0  pt-5 pt-md-0">
        <div className="row" style={{ paddingTop: "6rem" }}>
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-md-6 d-flex justify-content-center">
                <img src={ImgBride2} className="img-fluid" />
              </div>
              <div className="col-12 col-md-6 mt-5 mt-md-0  d-flex justify-content-center">
                <img src={ImgBride3} className="img-fluid " />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-100 my-5 py-4" />
      <div className="w-100 my-5 py-4" />

      <h2 className="font-PlayfairDisplay fw-bolder text-center mb-2">
        Los más populares
      </h2>
      <div
        className="d-flex w-100 justify-content-center align-content-center align-items-center"
        ata-aos="fade-up"
        data-aos-duration="1500"
      >
        <div className="LineRosa me-3" style={{ backgroundColor: "black" }} />
      </div>

      <div className="container p-3 mt-4">
        <div className="row">
          <div className="col-12">
            <CarruselPopulares />
          </div>
        </div>
      </div>
      <div className="w-100 my-5 py-4" />
    </>
  );
}

export default Bride;
