import React, { useEffect, useState } from "react";

import MisPedidos from "../../assets/MisPedidos.svg";
import Domicilios from "../../assets/Domicilios.svg";
import DoneIcon from "@mui/icons-material/Done";
import { useCartInfo } from "../../Context/CartInfoContext";
import Direccion from "./Direccion";
import Sucursal from "./Sucursales";
import { Link } from "react-router-dom";
import {
  Backdrop,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { BasicDatePicker } from "../../Includes/DatePicker";
import Horarios from "./Horarios";
import dayjs from "dayjs";
import { getPrecioZona } from "../Funciones/Orden";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

const DatosEnvio = ({ envio = 0 }) => {
  const { cartInfo, setCartInfo, orderInfo } = useCartInfo();
  const [isLoading, setIsloading] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState(cartInfo.deliveryMethod);
  const [domicilio, setDomicilio] = useState(cartInfo.domicilio);
  const [sucursal, setSucursal] = useState(cartInfo.sucursal);
  const [fecha, setFecha] = useState(
    cartInfo.deliveryDate == "" ? dayjs() : cartInfo.deliveryDate
  );
  const [horario, setHorario] = useState(cartInfo.deliveryTime);
  const [precioEnvio, setPrecioEnvio] = useState(cartInfo.precioEnvio);

  const [dedicatoria, setDedicatoria] = useState(
    cartInfo.dedicatoria ?? {
      envia: "",
      recibe: "",
      dedicatoria: "",
    }
  );

  const handlePlaceOrder = () => {
    cartInfo.deliveryMethod = deliveryMethod;
    cartInfo.deliveryDate = fecha;
    cartInfo.deliveryTime = horario;
    cartInfo.domicilio = domicilio;
    cartInfo.sucursal = sucursal;
    cartInfo.precioEnvio = precioEnvio;
    cartInfo.dedicatoria = dedicatoria;
    setCartInfo({ ...cartInfo });
  };

  useEffect(() => {
    handlePlaceOrder();
  }, [
    domicilio,
    sucursal,
    deliveryMethod,
    fecha,
    horario,
    precioEnvio,
    dedicatoria,
  ]);

  useEffect(() => {
    if (deliveryMethod == "DOMICILIO") {
      if (domicilio.id != 0) {
        setIsloading(true);
        getPrecioZona(domicilio.lat, domicilio.long)
          .then((data) => {
            setIsloading(false);
            if (data.length > 0) {
              let p = {
                idZona: data[0].ID,
                precioEnvio: data[0].PRECIO_DE_ENVIO,
                zona: "",
              };
              setPrecioEnvio(p);
            } else {
              MySwal.fire({
                title: "Alerta",
                html: "Por el momento no contamos con servicio en el domicilio seleccionado, iententa con otro ",
                icon: "info",
                confirmButtonColor: "#c9a396",
                denyButtonColor: "#979797",
                showConfirmButton: true,
                allowEscapeKey: false,
                allowOutsideClick: false,
                confirmButtonText: "Entendido",
              }).then((result) => {
                let p = {
                  idZona: 0,
                  precioEnvio: 0,
                  zona: "",
                };
                let dom = {
                  id: 0,
                  domicilio: "",
                  nombre: "",
                  tipo: "DOMICILIO",
                  lat: 0,
                  long: 0,
                };
                setDomicilio({ ...dom });
                setPrecioEnvio(p);
              });
            }
          })
          .catch((data) => {
            setIsloading(false);
          });
      }
    } else {
      let p = {
        idZona: 0,
        precioEnvio: 0,
        zona: "",
      };

      setPrecioEnvio(p);
      setIsloading(false);
    }
  }, [domicilio, deliveryMethod]);

  return (
    <>
      {isLoading && (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />{" "}
          <h4 className="ms-4">
            Estamos verificando la cobertura de tu dirección,
            <br />
            por favor espera...
          </h4>
        </Backdrop>
      )}
      <div className="pt-3 pb-5">
        <Button
          onClick={(e) => setDeliveryMethod("DOMICILIO")}
          className={
            "btn shadow-none btn-outline-black d-flex  mt-4 w-100 font-OxygenMedium text-star  py-3 px-4 " +
            (deliveryMethod == "DOMICILIO" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={MisPedidos} className="img-fluid me-3" />
            <span>Entregar a domicilio </span>
            <div className="flex-grow-1 text-end">
              {deliveryMethod == "DOMICILIO" && <DoneIcon className="" />}
            </div>
          </div>
        </Button>
        {deliveryMethod == "DOMICILIO" ? (
          <Direccion domicilio={domicilio} setDomicilio={setDomicilio} />
        ) : (
          ""
        )}

        <Button
          onClick={(e) => setDeliveryMethod("SUCURSAL")}
          className={
            "btn shadow-none btn-outline-black d-flex  mt-4 w-100 font-OxygenMedium text-star  py-3 px-4 " +
            (deliveryMethod == "SUCURSAL" ? " btn-michelle " : "")
          }
          variant="contained"
        >
          <div className="d-flex justify-content-star w-100">
            <img src={Domicilios} className="img-fluid me-3" />
            <span>Recoger en sucursal </span>
            <div className="flex-grow-1 text-end">
              {deliveryMethod == "SUCURSAL" && <DoneIcon className="" />}
            </div>
          </div>
        </Button>
        {deliveryMethod == "SUCURSAL" ? (
          <Sucursal sucursal={sucursal} setSucursal={setSucursal} />
        ) : (
          ""
        )}
        <div className="row mt-4 mb-2">
          {sucursal.id != 0 || domicilio.id != 0 ? (
            <>
              <div className="col-12 mt-3 mb-2 ">
                <h5 className=" text-star mb-3 fw-bolder font-PlayfairDisplay">
                  {deliveryMethod == "SUCURSAL"
                    ? "Fecha de recolección"
                    : "Fecha de envío"}
                </h5>
              </div>

              <div className="col-12 col-md-6  mb-3 ">
                <BasicDatePicker
                  size="small"
                  format="DD/MM/YYYY"
                  label="Fecha de entrega"
                  fecha={fecha}
                  setFecha={setFecha}
                  // fechaMin={dayjs()}
                  fechaMin={dayjs("2024-02-12")}
                  // fechaMax={dayjs().add(7, "day")}
                  fechaMax={dayjs("2024-02-12").add(7, "day")}
                />
              </div>

              <div className="col-12 col-md-6 mb-3  ">
                <Horarios
                  size="small"
                  horario={horario}
                  setHorario={setHorario}
                  fecha={dayjs(fecha).format("YYYY-MM-DD")}
                  sucursal={deliveryMethod == "SUCURSAL" ? sucursal.id : 0}
                />
              </div>

              <div className="col-12 mt-3 mb-2 ">
                <h5 className=" text-star mb-3 fw-bolder font-PlayfairDisplay">
                  Dedicatoria{" "}
                  <small style={{ fontSize: "11px" }}>(datos opcionales)</small>
                </h5>
              </div>
              <div className="col-12 col-md-6  mb-3 ">
                <TextField
                  onChange={(event) => {
                    dedicatoria.envia = event.target.value;
                    setDedicatoria({ ...dedicatoria });
                  }}
                  value={dedicatoria.envia}
                  fullWidth
                  size="small"
                  name="envia"
                  label="Quién envía"
                  placeholder="Anónimo"
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                />
              </div>

              <div className="col-12 col-md-6 mb-3 ">
                <TextField
                  onChange={(event) => {
                    dedicatoria.recibe = event.target.value;
                    setDedicatoria({ ...dedicatoria });
                  }}
                  value={dedicatoria.recibe}
                  fullWidth
                  size="small"
                  name="envia"
                  label="Quién recibe"
                  placeholder="Persona quién recibe"
                  variant="outlined"
                  inputProps={{ maxLength: 200 }}
                />
              </div>
              <div className="col-12   ">
                <TextField
                  onChange={(event) => {
                    dedicatoria.dedicatoria = event.target.value;
                    setDedicatoria({ ...dedicatoria });
                  }}
                  value={dedicatoria.dedicatoria}
                  fullWidth
                  multiline={true}
                  maxRows={3}
                  minRows={3}
                  size="small"
                  name="envia"
                  label="Mensaje de dedicatoria"
                  placeholder="(Máx 1500)"
                  variant="outlined"
                  inputProps={{ maxLength: 1500 }}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>

        {deliveryMethod != "" &&
        horario.length != 0 &&
        horario.id != 0 &&
        ((deliveryMethod == "SUCURSAL" && sucursal.id != 0) ||
          (deliveryMethod == "DOMICILIO" && domicilio.id != 0)) ? (
          <Link
            className=""
            to="/orden/pay"
            style={{ textDecoration: "none", color: "black" }}
          >
            <Button
              loadingPosition="start"
              className="btn btn-michelle mt-4 w-100 font-OxygenMedium  py-2 px-4 "
              variant="contained"
            >
              <span>Continuar a métodos de pago </span>
            </Button>
          </Link>
        ) : (
          <Button
            disabled
            loadingPosition="start"
            className="btn btn-michelle mt-4 w-100 font-OxygenMedium  py-2 px-4 "
            variant="contained"
          >
            <span>Continuar a métodos de pago</span>
          </Button>
        )}

        <center>
          <Link
            className="w-100 text-center"
            to={"/orden"}
            style={{
              textTransform: "none",
              textDecoration: "none",
            }}
          >
            <Typography className="m-1 mt-3 mb-5 text-center font-OxygenBold text-rosa  animated-text">
              Regresar a carrito
            </Typography>
          </Link>
        </center>
      </div>
    </>
  );
};

export default DatosEnvio;
