import React, { createContext, useEffect, useState } from "react";
import { Badge, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import { getLocalStorageJWT } from "../Context/Storage";
import Error404 from "./Error404";
import Dashboard from "../Home/Home";
import AccountMenu from "./MenuPerfil";
import imgFavoritos from "./../assets/Favoritos.svg";
import Whats from "./../assets/whatsapp.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Login from "../Login/componentes/login";
import Registro from "../Login/componentes/Registro";
import loogo from "./../assets/LogoNegrocreativoMichelle.svg";
import Footer from "./Footer";
import MiPerfil from "../MiPerfil/Componentes/Perfil";
import Countdown from "./CountDown";
import NosotrosRoute from "../Nosotros/Nosotros";
import ProductosRote from "../Productos/Productos";
import Contacto from "../Contacto/Contacto";
import Workshop from "../Workshop/Workshop";
import { CartProvider } from "../Context/CartContext";
import { CartInfoProvider } from "../Context/CartInfoContext";
import CarritoMini from "./CarritoMini";
import OrdenRoute from "../Orden/OrdenRoute";
import Bride from "../Bride/Componentes/Bride";
import Privacidad from "../PoliticaPrivacidad/PoliticaPrivacidad";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export const IconContext = createContext();

export default function Menu() {
  const [url, setUrl] = useState("");

  const [IsLoading, setIsLoading] = useState(true);
  const [Values, setValues] = useState({
    img: "AccEditarImagen",
  });

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          zIndex: "50",
        }}
      >
        <a
          href="https://api.whatsapp.com/send?phone=3323437579"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "60px",
            height: "60px",
            backgroundColor: "#25d366",
            borderRadius: "50%",
            boxShadow: "2px 2px 3px #999",
          }}
        >
          <img src={Whats} className="img-fluid" />
        </a>
      </div>

      <CartProvider>
        <CartInfoProvider>
          <IconContext.Provider value={{ url, setUrl }}>
            <Navbar
              expand="sm"
              bg="withe"
              fixed="top"
              style={{ backgroundColor: "white", height: "5rem" }}
            >
              <Container>
                <Navbar.Brand href="#">
                  <Link
                    to="home"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <img src={loogo} className="img-fluid img-brand" />
                  </Link>
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="bg-white" id="basic-navbar-nav">
                  <Nav className="ms-auto">
                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="home"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname == "/" ||
                          location.pathname == "/home"
                            ? "#C9A396"
                            : "black",
                      }}
                    >
                      Inicio
                    </Link>

                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="nosotros"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname == "/nosotros"
                            ? "#C9A396"
                            : "black",
                      }}
                    >
                      Nosotros
                    </Link>
                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="productos"
                      style={{
                        whiteSpace:"nowrap",
                        textDecoration: "none",
                        color:
                          location.pathname == "/productos"
                            ? "#C9A396"
                            : "black",
                      }}
                    >
                      Nuestros productos
                    </Link>

                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="bride"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname == "/bride" ? "#C9A396" : "black",
                      }}
                    >
                      Bride
                    </Link>
                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="workshop"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname == "/workshop"
                            ? "#C9A396"
                            : "black",
                      }}
                    >
                      Workshop
                    </Link>

                    <Link
                      className="p-2 me-2 font-Oxygen animated-text"
                      to="contacto"
                      style={{
                        textDecoration: "none",
                        color:
                          location.pathname == "/contacto"
                            ? "#C9A396"
                            : "black",
                      }}
                    >
                      Contacto
                    </Link>

                    <div
                      className="d-flex mt-4 mt-md-0 justify-content-start justify-content-md-end w-100 align-content-center"
                      style={{ backgroundColor: "#FFFFFF" }}
                    >
                      <div className="px-0 d-flex algin-content-center">
                        <Tooltip title="Mis favoritos">
                          <Link to="productos/favoritos">
                            <IconButton className="me-3 me-0 mt-1">
                              <img src={imgFavoritos} />
                            </IconButton>
                          </Link>
                        </Tooltip>

                        <Tooltip title="Carrito">
                          <CarritoMini />
                        </Tooltip>

                        {getLocalStorageJWT() != "" ? (
                          <AccountMenu
                            Values={Values}
                            IsLoading={IsLoading}
                            className="align-self-center"
                          />
                        ) : (
                          <Link
                            className="p-2 me-2 font-Oxygen"
                            to="login"
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            Iniciar sesión
                          </Link>
                        )}
                      </div>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>

            <Box sx={{ display: "flex" }}>
              <Box
                component="main"
                sx={{ flexGrow: 1 }}
                className="p-0"
                style={{
                  backgroundColor: "white",
                  minHeight: "calc(100vh - 5rem)",
                }}
              >
                <Routes>
                  <Route path="home" element={<Dashboard />}></Route>
                  <Route path="/" element={<Dashboard />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/registro/*" element={<Registro />}></Route>
                  <Route path="/perfil/*" element={<MiPerfil />}></Route>
                  <Route
                    path="/productos/*"
                    element={<ProductosRote />}
                  ></Route>
                  <Route path="/bride" element={<Bride />}></Route>
                  <Route path="/nosotros/*" element={<NosotrosRoute />}></Route>
                  <Route path="/orden/*" element={<OrdenRoute />}></Route>
                  <Route path="/workshop/*" element={<Workshop />}></Route>
                  <Route path="/contacto/*" element={<Contacto />}></Route>
                  <Route
                    path="/politica-privacidad"
                    element={<Privacidad />}
                  ></Route>

                  <Route path="*" element={<Countdown />} />
                </Routes>

                <Footer />
              </Box>
            </Box>
          </IconContext.Provider>
        </CartInfoProvider>
      </CartProvider>
    </>
  );
}
