import React, { useEffect, useState } from "react";
import FotoBanner from "../assets/Home/FotoBanner.webp";
import Banner2 from "../assets/Home/Banner2.webp";
import Workshops from "../assets/Home/Workshops.webp";
import { getLocalStorageJWT } from "../Context/Storage";

import AOS from "aos";
import "aos/dist/aos.css";
import { Button, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import CarruselCategorias from "../Categorias/Componentes/CarruselCategorias";
import Video from "./Componentes/Video";
import CarruselNovedades from "../Productos/Componentes/CarruselNovedades";
import { ForkLeft } from "@mui/icons-material";
import { useMediaQuery } from "@material-ui/core";

export default function Dashboard() {
  const [isLoged, setIsLoged] = useState(false);
  const urls = {
    BannerHome1:
     "https://florcreativa.blob.core.windows.net/imagenes/bannerWeb.webp",// "https://florcreativa.blob.core.windows.net/imagenes/BannerHome1.webp",
    BannerHome2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerHome2.webp",
    BannerHome3:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerHome3.webp",
    GraficoHomeFreeStyleBlanco:
      "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleBlanco.svg",
    GraficoHomeFreeStyleNegro:
      "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleNegro.svg",
    BannerWorkshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerWorkshops2",
    BannerWorkshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/BannerWorkshops2.webp",
    Workshops2:
      "https://florcreativa.blob.core.windows.net/imagenes/Workshops2.webp",
  };
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(() => {
    getInfoUsuario().then(setTimeout(() => {}, 1500));
    AOS.init();
  }, []);
  /*const isTabletOrMobile = useMediaQuery("(max-width: 1200px)");

  useEffect(() => {
    console.log("isTabletOrMobile", isTabletOrMobile);
  }, [isTabletOrMobile]);*/

  return (
    <>
      <div className="espacio-header" />
      <div className="container-fluid px-0">
        <div
          id="carouselExampleInterval"
          className="carousel slide"
          data-bs-ride="carousel"
          //style={{ background: "red", padding: "2px" }}
        >
          <div className="carousel-inner">
            {/*
            
            <div className="carousel-item active" data-bs-interval="10000">
              <div className="w-100" style={{ backgroundColor: "#BFB7B9" }}>
                <div
                  className="container-fluid"
                  style={{ backgroundColor: "#BFB7B9" }}
                >
                  <div className="row">
                    <div
                      className=" col-12 col-sm-6 col-md-7 col-lg-8 p-3  mt-5 mt-md-0  d-flex justify-content-center align-items-center "
                      data-aos="zoom-in-down"
                    >
                      <h1
                        className="tituloWhiteHome text-white font-PlayfairDisplay"
                        style={{ fontWeight: "100" }}
                      >
                        Flowers
                        <br />
                        for the mother
                      </h1>
                    </div>
                    <div
                      className=" col-12 col-sm-6 col-md-5 col-lg-4 p-0   "
                      style={{
                        //backgroundImage: `url(${row.IMG != "" ? row.IMG : ProdcutosArreglos})`,
                        backgroundImage: `url(${Banner2})`,
                        minHeight: "488px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item" data-bs-interval="2000">
              <div className="w-100" style={{ backgroundColor: "#dcdbd6" }}>
                <div
                  className="container-xl"
                  style={{ backgroundColor: "#dcdbd6" }}
                >
                  <div className="d-flex justify-content-between w-100 align-items-center align-content-center flex-wrap flex-sm-nowrap">
                    <div
                      className="p-3 aligin-self-center mt-5 mt-md-0 w-100 w-md-50"
                      data-aos="zoom-in-down"
                    >
                      <h2 className="font-Oxygen">Nos encantaría ser parte</h2>
                      <h1 className="tituloWhiteHome text-white font-PlayfairDisplay">
                        De ese evento
                        <br />
                        tan especial
                      </h1>
                    </div>
                    <div className="p-3 aligin-self-center">
                      <img src={FotoBanner} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            */}

            <div
              className="carousel-item active"
              data-bs-interval="50000"
              style={{ maxHeight: "87vh" }}
            >
              <div
                className=" img-carroucel-home"
                style={{
                  backgroundImage: `url("${urls.BannerHome1}")`,
                }}
              /> 
            </div>
            <div
              className="carousel-item"
              data-bs-interval="5000"
              style={{ maxHeight: "87vh" }}
            >
              <div
                className="img-carroucel-home"
                style={{
                  backgroundImage: `url("${urls.BannerHome2}")`,
                }}
              />
              <div
                className="carousel-caption d-flex justify-content-start align-content-start"
                style={{
                  //backgroundColor: "yellow",
                  position: "absolute",
                  bottom: 0,
                  left: 100,
                }}
              >
                <img
                  src={urls.GraficoHomeFreeStyleBlanco}
                  style={{
                    maxWidth: "350px",
                    maxHeight: "350px",
                    //backgroundColor: "red",
                  }}
                />
              </div>
            </div>
            <div
              className="carousel-item"
              data-bs-interval="5000"
              style={{ maxHeight: "87vh" }}
            >
              <div
                className="img-carroucel-home"
                style={{
                  backgroundImage: `url("${urls.BannerHome3}")`,
                }}
              />
              <div
                className="carousel-caption d-flex justify-content-start align-content-start"
                style={{
                  //backgroundColor: "yellow",
                  position: "absolute",
                  bottom: 0,
                  left: 100,
                }}
              >
                <img
                  src={urls.GraficoHomeFreeStyleBlanco}
                  style={{
                    maxWidth: "350px",
                    maxHeight: "350px",
                    //backgroundColor: "red",
                  }}
                />
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleInterval"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>

        <div
          className="w-100 "
          style={{ backgroundColor: "#ebe1d6" }}
          data-aos="fade-up"
        >
          <div className="container p-3">
            <h2 className="font-PlayfairDisplay ms-5 mt-4 ms-md-0">
              Productos
            </h2>
            <div
              className="d-flex w-100 justify-content-start 
              mb-4 
              align-content-center align-items-center"
              ata-aos="fade-up"
              data-aos-duration="1500"
            >
              <div>
                <h6 className="font-PlayfairDisplay text-rosa">
                  (Our products)
                </h6>
              </div>
              <div className="LineRosa ms-3" />
            </div>
            <div className="row">
              <div className="col-12">
                <CarruselCategorias />
              </div>
              <div className="col-12 text-center mb-5">
                <Link
                  to="/productos"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Button
                    className="btn shadow-none  btn-michelle-rouse font-OxygenMedium  py-2 px-4 mt-5  me-auto me-md-0 "
                    variant="contained"
                    style={{ max: "400px" }}
                  >
                    <span className={"px-2 font-OxygenMedium text-white"}>
                      Ver productos
                    </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 my-5 py-5" />
        <div
          className="w-100 "
          style={
            {
              //background: "brown"
            }
          }
        >
          <div
            className="container"
            style={
              {
                //background: "yellow"
              }
            }
          >
            <div
              className="d-flex justify-content-between w-100 h-100 align-items-center align-content-center flex-wrap flex-sm-nowrap ps-4"
              style={
                {
                  //background: "green",
                  // padding: "2px"
                }
              }
            >
              <div
                className="p-3 aligin-self-center pe-5 "
                data-aos="zoom-in"
                style={
                  {
                    //background: "red",
                  }
                }
              >
                <div
                  style={{
                    rotate: "-90deg",
                    position: "absolute",
                    left: "0px",
                    transformOrigin: "bottom left",
                    top: "134px",
                  }}
                >
                  <div
                    className="d-flex w-100 justify-content-center align-content-star  align-items-star "
                    ata-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <div>
                      <h6 className="font-PlayfairDisplay text-rosa text-nowrap">
                        (About Us)
                      </h6>
                    </div>
                    <div className="LineRosa ms-3" />
                  </div>
                </div>

                <div className="d-flex justify-content-start align-items-start flex-column">
                  <div className="d-flex justify-content-start ">
                    <h2
                      className="font-PlayfairDisplay "
                      style={{ fontSize: 30 }}
                    >
                      Un
                    </h2>
                    <h2
                      className="font-PlayfairDisplayItalic mb-3  ms-2"
                      style={{ fontSize: 30 }}
                    >
                      poco
                    </h2>
                    <h2
                      className="font-PlayfairDisplay mb-3  ms-2"
                      style={{ fontSize: 30 }}
                    >
                      acerca de
                    </h2>
                  </div>
                  <h2
                    className=" mb-4 w-100 "
                    style={{
                      fontWeight: "bold",
                      fontFamily: "PlayfairDisplay",
                      fontSize: 30,
                    }}
                  >
                    nosotros
                  </h2>
                </div>
                <p className=" ms-2 font-Oxygen mb-2 pe-5 me-3">
                  Empresa tapatía dedicada a la creación de experiencias
                  personalizadas a través del amor por el diseño floral.
                </p>
                <p className="ms-2 font-Oxygen mb-2 pe-5 me-3">
                  El principio básico de todo lo que creamos se encuentra en la
                  naturaleza, raíz y fruto de nuestra inspiración y creatividad.
                </p>
                <center>
                  <Link
                    to="/nosotros"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    <Button
                      className="btn shadow-none  btn-michelle font-OxygenMedium  py-2 px-4 mt-5  me-auto me-md-0 "
                      variant="contained"
                      style={{ max: "400px" }}
                    >
                      <span className={"px-2 font-OxygenMedium "}>
                        Nosotros
                      </span>
                    </Button>
                  </Link>
                </center>
              </div>
              <Video />
            </div>
          </div>
        </div>
        <div className="w-100 my-5 py-5" />

        {/* <div className="w-100 my-5 py-5" />

        <h2
          className="font-PlayfairDisplayItalic text-center mb-4"
          data-aos="fade-up"
        >
          Novedades
        </h2>
        <div
          className="d-flex w-100 justify-content-center align-content-center align-items-center"
          ata-aos="fade-up"
          data-aos-duration="1500"
        >
          <div className="LineRosa me-3" />
          <div>
            <h6 className="font-PlayfairDisplayItalic text-rosa">(New in)</h6>
          </div>
          <div className="LineRosa ms-3" />
        </div>

        <div className="container p-3">
          <div className="row">
            <div className="col-12">
              <CarruselNovedades />
            </div>
          </div>
                </div>*/}

        <div className="w-100 my-5 py-5" />
        <div
          className="container-fluid"
          style={{ backgroundColor: "#D7D7D7" }}
          dta-aos="fade-up"
        >
          <div className="row">
            <div
              className="col-12 col-sm-4 col-md-6 col-lg-8"
              style={{
                //backgroundImage: "url(" + Workshops + ")",
                backgroundImage: "url(" + urls.Workshops2 + ")",
                backgroundPositionX: "center",
                backgroundPositionY: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                minHeight: "400px",
              }}
            ></div>
            <div className="col-12 col-sm-8 col-md-6 col-lg-4 d-flex justify-content-center">
              <div
                className="my-5 py-4 p-2"
                style={{ maxWidth: "500px" }}
                data-aos="fade-up"
              >
                <div className="d-none d-md-block">
                  <div className="w-100 my-5 py-5" />
                </div>
                <div className="row">
                  {/*<div className="col-2">
                    <div
                      style={{
                        transform: " rotate(-90deg)",
                        marginTop: "68px",
                      }}
                    >
                      <div
                        className="d-flex w-100 justify-content-center align-content-center align-items-center"
                        ata-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <div>
                          <h6 className="font-PlayfairDisplayItalic  text-nowrap">
                            (Workshops)
                          </h6>
                        </div>
                        <div>
                          <div
                            className="mb-1 ms-2"
                            style={{
                              height: "1px",
                              backgroundColor: "black",
                              width: "50px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                          </div>*/}
                  <div className="col-12 d-flex align-items-center justify-content-center flex-column">
                    <div className="d-flex justify-content-start align-items-start flex-column">
                      <div className="d-flex justify-content-start ">
                        <h2
                          className="font-PlayfairDisplay "
                          style={{ fontSize: 40 }}
                        >
                          Conoce
                        </h2>
                        <h2
                          className="font-PlayfairDisplayItalic mb-3  ms-4"
                          style={{ fontSize: 40 }}
                        >
                          nuestros
                        </h2>
                      </div>
                      <h2
                        className=" mb-4 w-100 "
                        style={{
                          fontWeight: "bold",
                          fontFamily: "PlayfairDisplay",
                          fontSize: 40,
                        }}
                      >
                        Workshops
                      </h2>
                    </div>

                    <p
                      className="font-Oxygen mb-2"
                      style={{ fontSize: 19, textAlign: "justify" }}
                    >
                      Somos fieles creyentes de compartir la sabiduría floral,
                      es por ello que diseñamos una clase de talleres para
                      brindar parte de nuestro conocimiento a todo aquel que se
                      sienta creativo o quiera aprender un poco de nuestra
                      magia.
                    </p>

                    <Link
                      to="/workshop"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button
                        className="btn shadow-none  btn-michelle font-OxygenMedium  py-2 px-4 mt-5  me-auto me-md-0 "
                        variant="contained"
                        style={{ max: "400px" }}
                      >
                        <span
                          className={"px-2 font-PlayfairDisplayItalic "}
                          style={{ fontSize: 25, fontWeight: 900 }}
                        >
                          Ver más
                        </span>
                      </Button>
                    </Link>
                  </div>
                </div>

                <div className="w-100 my-5 py-5" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
