import React, { useEffect, useState } from "react";
import { getSucursalesByKey } from "../Lib/Direcciones/Funciones/Direcciones";
import CarruselRandom from "../Productos/Componentes/CarruselRandom";

function Workshop() {
  const BannerWork =
    "https://florcreativa.blob.core.windows.net/imagenes/BannerWorkshops2.webp";

  const GraficoHomeFreeStyleNegro =
    "https://florcreativa.blob.core.windows.net/imagenes/GraficoHomeFreeStyleNegro.svg";
  useEffect(() => {
    getSucursalesByKey();
  }, []);
  return (
    <>
      <div className="espacio-header" />
      <div className="container-fluid px-0">
        {/*<div className="container-fluid" style={{ backgroundColor: "#baa294" }}>
          <div className="row">
            <div
              className="col-12 col-sm-7 col-md-6
           d-flex    
           align-items-center
          justify-content-center                   
          "
            >
              <div>
                <div className="my-5 my-md-0 align-self-center">
                  <h4
                    className="font-Oxygen text-white"
                    style={{ fontSize: "1.5rem", marginBottom: "0.5rem" }}
                  >
                    Conoce nuestros
                  </h4>
                  <h4
                    className="font-PlayfairDisplayItalic text-white"
                    style={{ fontSize: "4rem" }}
                  >
                    Workshops
                  </h4>
                </div>
              </div>
            </div>
            <div className=" col-12 col-sm-5 col-md-6 p-0   d-flex  justify-content-end ">
              <img src={BannerWork} className="img-fluid" />
            </div>
          </div>
  </div>*/}
        <div
          className="banner-contacto img-fluid"
          style={{
            backgroundImage: `url("${BannerWork}")`,
          }}
        >
          <div
            className=" d-flex flex-wrap  align-items-center justify-content-start ps-5"
            style={{ height: 500, background: "" }}
          >
            <div className="d-flex justify-content-start align-items-start flex-column ps-5">
              <div className="d-flex justify-content-start ">
                <h2
                  className="font-PlayfairDisplay text-white"
                  style={{ fontSize: 40 }}
                >
                  Conoce
                </h2>
                <h2
                  className="font-PlayfairDisplayItalic text-white mb-3  ms-3"
                  style={{ fontSize: 40 }}
                >
                  nuestros
                </h2>
              </div>
              <h2
                className=" mb-4 w-100 text-white "
                style={{
                  fontWeight: "bold",
                  fontFamily: "PlayfairDisplay",
                  fontSize: 40,
                }}
              >
                Workshops
              </h2>
            </div>
          </div>
        </div>

        <div className="w-100 my-4 py-4" />
        {/*<div className="d-flex justify-content-center">
          <h1
            className="text-black font-PlayfairDisplay text-center"
            style={{ fontWeight: "100" }}
          >
            Vivimos para crear y compartirlo
          </h1>
        </div>
        <div className="w-100 my-3 py-3" />
        <div className="d-flex align-items-center justify-content-center">
          <div className="LineRosa ms-4" />
          <h6
            className="font-PlayfairDisplay ms-4"
            style={{ color: "#c8a295", fontSize: "20px" }}
          >
            (Never stop creating)
          </h6>
          <div className="LineRosa ms-4" />
        </div>*/}
        <div className="d-flex align-items-center justify-content-center">
          <img
            src={GraficoHomeFreeStyleNegro}
            alt="GraficoHomeFreeStyleNegro"
            height={300}
            width={300}
          />
        </div>
        <div className="w-100 " />
        <div className="d-flex align-items-center justify-content-center p-3">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <p className="font-Oxygen text-black text-center">
                Somos fieles creyentes de compartir tu sabiduría, es por ello
                que diseñamos una clase de talleres para <br /> brindar parte de
                nuestro conocimiento a todo aquel que se sienta creativo o
                quiera aprender un poco de <br /> nuestra magia.
              </p>
            </div>
            <div className="col-sm-12 col-md-12">
              <p className="font-Oxygen text-black text-center">
                Está pendiente de nuestras redes sociales ya que ahí
                comunicaremos nuestros próximos workshops.
              </p>
            </div>
          </div>
        </div>
        <div className="w-100 my-3 py-3" />
        <div className="d-flex align-items-center justify-content-center p-3">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <a
                href="mailto:ventas.mpourroy@gmail.com"
                style={{ textDecoration: "none" }}
              >
                <h6
                  className="font-Oxygen text-center"
                  style={{ color: "#c8a295", fontWeight: "bold" }}
                >
                  @michellepourroy
                </h6>
              </a>
            </div>
          </div>
        </div>
        <div className="w-100 my-5 py-4" />
        <div className="container p-3">
          <div className="row">
            <div className="col-12">
              <CarruselRandom />
            </div>
          </div>
        </div>
        <div className="w-100 my-5 py-4" />
      </div>
    </>
  );
}

export default Workshop;
