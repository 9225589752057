import React, { useEffect, useState } from "react";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { Link } from "react-router-dom";
import { getLista } from "../Funciones/Categorias";
import { Button } from "react-bootstrap";
import Loading from "../../Includes/Loading";

const Categorias = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const getProductos = () => {
    setIsLoading(true);
    getLista()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };
  useEffect(() => {
    getProductos();
  }, []);

  return (
    <>
      <div className="espacio-header" />

      <div className="w-100 " style={{ backgroundColor: "#ebe1d6" }}>
        <div className="container p-3 pb-0">
          <h2 className="font-PlayfairDisplay ms-5 mt-4 ms-md-0 text-center">
            Productos
          </h2>
          <center>
            <div
              className="d-flex w-100 justify-content-center w-100 
              mb-4 
              align-content-center align-items-center flex-nowrap"
              ata-aos="fade-up"
              data-aos-duration="1500"
            >
              {" "}
              <div className="LineRosa me-3" />
              <div>
                <h6 className="font-PlayfairDisplay text-rosa ">
                  (Our products)
                </h6>
              </div>
              <div className="LineRosa ms-3" />
            </div>
          </center>

          <div className="row">
            {IsLoading ? (
              <div className="pt-5 w-100">
                <Loading />
              </div>
            ) : (
              Lista.map((row) => (
                <>
                  <div key={row.ID} className="col-12 col-sm-6 col-md-4">
                    <a
                      href={"/productos/cat/" + row.ID}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="p-2">
                        <div
                          className="w-100 container-categoria"
                          style={{
                            backgroundImage: `url(${
                              row.IMG != "" || row.IMG == null
                                ? row.IMG
                                : Skeleton2
                            })`,
                            minHeight: "400px",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            cursor: "pointer",
                            transition: " all 0.5s ease",
                          }}
                        ></div>
                        <h4 className="font-PlayfairDisplay mb-4 mt-2 text-center">
                          {row.NOMBRE}
                        </h4>
                      </div>
                    </a>
                  </div>
                </>
              ))
            )}
            <div className="col-12 text-center mb-5">
              <Link to="/" style={{ textDecoration: "none", color: "black" }}>
                <Button
                  className="btn shadow-none  btn-michelle-rouse font-OxygenMedium  py-2 px-4 mt-5  me-auto me-md-0 "
                  variant="contained"
                  style={{ max: "400px" }}
                >
                  <span className={"px-2 font-OxygenMedium text-white"}>
                    Ir a home
                  </span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Categorias;
