import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Favoritos from "./../../assets/Favoritos.svg";
import AccEliminar from "./../../assets/AccEliminar.svg";
import { Badge, Button, ButtonGroup, Divider, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CartContext } from "../../Context/CartContext";
import { useContext } from "react";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    padding: "0 0px",
    width: "5px",
  },
}));

const Carrito = () => {
  const {
    cartItems,
    addToCart,
    removeFromCart,
    clearCart,
    deleteItem,
    getItemCount,
  } = useContext(CartContext);

  return (
    <>
      <div className="mt-4 p-3">
        <div className="p-0 w-100">
          <div className="w-100 d-flex justify-content-between flex-nowrap align-content-center align-items-center">
            <div className="ms-3">
              <h5
                className=" text-center mb-0 aligin-self-center  font-PlayfairDisplay "
                style={{ fontWeight: "bold" }}
              >
                Carrito de compras
              </h5>
            </div>
          </div>

          {cartItems.length == 0 ? (
            <>
              <center>
                <Typography className=" mt-5 mb-5 text-center font-OxygenBold text-rosa  ">
                  Tu carrito esta vacío
                </Typography>
              </center>
            </>
          ) : (
            <>
              <div className="p-3 pb-2">
                <div className="row">
                  {cartItems.map((row) => (
                    <div>
                      <div className="col-12">
                        <div className="d-flex ">
                          <div
                            style={{
                              backgroundImage: `url(${
                                row.img != "" ? row.img : Skeleton2
                              })`,
                              height: "100px",
                              width: "100px",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              position: "relative",
                            }}
                          ></div>
                          <div className="flex-grow-1 ps-3">
                            <div className="w-100 d-flex justify-content-between flex-nowrap align-content-center align-items-center">
                              <div>
                                <h6 className="font-Oxygen mb-2 text-star">
                                  {JSON.parse(row.descuento).TIPO_DESCUENTO ===
                                  0 ? (
                                    <>
                                      {row.descripcion}
                                      {row.id_color != 0 ? (
                                        <>
                                          <p>Color del producto: {row.color}</p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {row.id_talla != 0 ? (
                                        <>
                                          <p>
                                            Tamaño del producto: {row.talla}{" "}
                                          </p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {row.descripcion}
                                      {row.id_color != 0 ? (
                                        <>
                                          <p>Color del producto: {row.color}</p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {row.id_talla != 0 ? (
                                        <>
                                          <p>
                                            Tamaño del producto: {row.talla}{" "}
                                          </p>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      <div
                                        className="font-OxygenBold mt-2 "
                                        style={{
                                          color: "#66BEA0",
                                        }}
                                      >
                                        {JSON.parse(row.descuento)
                                          .TIPO_DESCUENTO === 1 ? (
                                          <>
                                            {
                                              JSON.parse(row.descuento)
                                                .DESCUENTO
                                            }{" "}
                                            % de descuento
                                          </>
                                        ) : (
                                          <>
                                            {formatMoneda(
                                              parseFloat(
                                                JSON.parse(row.descuento)
                                                  .DESCUENTO
                                              )
                                            )}{" "}
                                            pesos de descuento
                                          </>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </h6>
                              </div>

                              <div className="ms-3">
                                <h6 className="font-OxygenBold  mt-2 text-end">
                                  {JSON.parse(row.descuento).TIPO_DESCUENTO ===
                                  0 ? (
                                    <>
                                      {formatMoneda(
                                        parseFloat(row.precio * row.cantidad)
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <span>
                                        {formatMoneda(
                                          parseFloat(
                                            JSON.parse(row.descuento)
                                              .PRECIO_DESCUENTO
                                          ) * row.cantidad
                                        )}
                                        <span
                                          className="p-2"
                                          style={{
                                            color: "#BFB7B9",
                                            textDecoration: "line-through",
                                          }}
                                        >
                                          {formatMoneda(
                                            parseFloat(
                                              row.precio * row.cantidad
                                            )
                                          )}
                                        </span>
                                      </span>
                                    </>
                                  )}
                                </h6>
                              </div>
                            </div>

                            <ButtonGroup
                              className="buttonGroupCant mt-3"
                              variant="outlined"
                              aria-label="outlined button group"
                            >
                              <Button
                                className="btnAdd"
                                onClick={() => {
                                  removeFromCart({
                                    id: row.id,
                                    cantidad: 1,
                                    descripcion: row.descripcion,
                                    img: row.img,
                                    precio: row.precio,
                                    descuento: row.descuentos,
                                    dias_renta: 0,
                                    id_color: row.id_color,
                                    id_talla: row.id_talla,
                                    color: row.color,
                                    talla: row.talla,
                                  });
                                }}
                              >
                                -
                              </Button>
                              <Button disabled className="btnCant">
                                {getItemCount({
                                  id: row.id,
                                  id_color: row.id_color,
                                  id_talla: row.id_talla,
                                })}
                              </Button>
                              <Button
                                onClick={() => {
                                  addToCart({
                                    id: row.id,
                                    cantidad: 1,
                                    descripcion: row.descripcion,
                                    img: row.img,
                                    precio: row.precio,
                                    descuento: row.descuentos,
                                    dias_renta: 0,
                                    id_color: row.id_color,
                                    id_talla: row.id_talla,
                                    color: row.color,
                                    talla: row.talla,
                                  });
                                }}
                                className="btnDism"
                              >
                                +
                              </Button>
                            </ButtonGroup>
                            <div className="w-100 mt-3 d-flex justify-content-star flex-nowrap align-content-center align-items-center">
                              <div style={{ marginLeft: "-10px" }}>
                                <IconButton
                                  aria-label="Close"
                                  onClick={() => {
                                    // deleteItem({ id: row.id });
                                  }}
                                >
                                  <img src={Favoritos} />
                                </IconButton>

                                <IconButton
                                  aria-label="Close"
                                  onClick={() => {
                                    deleteItem({ id: row.id });
                                  }}
                                >
                                  <img src={AccEliminar} />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 py-2">
                        <Divider light />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="p-3 pt-1">
                <Typography
                  onClick={() => {
                    clearCart();
                  }}
                  className="mt-3 text-center font-OxygenBold    animated-text"
                  style={{ cursor: "pointer" }}
                >
                  <img src={AccEliminar} className="mb-1" />
                  <span className="ms-2">Vaciar carrito</span>
                </Typography>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Carrito;
