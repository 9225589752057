import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  setLocalStorageJWT,
  setLocalStorage,
  getLocalStorageJWT,
  clearStorageJWT,
} from "../../Context/Storage";
import BACKEND_URL, { KEY } from "../../Context/backend";
import loogo from "./../../assets/LogoNegrocreativoMichelle.svg";
import { guardar } from "../funciones/login";

import {
  Alert,
  Backdrop,
  CircularProgress,
  Snackbar,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import FacebookButton from "./FacebookButton";
import GoogleButton from "./GoogleButton";
import Swal from "sweetalert2";

export default function MediaCard() {
  const [Values, setValues] = useState({
    Correo: "",
    Contraseña: "",
  });
  const [openLoading, setOpenLoading] = useState(false);
  const [CorreoError, setCorreoError] = useState(false);
  const [ContraseñaError, setContraseñaError] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoged, setIsLoged] = useState(false);

  const handlInputChange = ({ target }) => {
    setValues({
      ...Values,
      [target.name]: target.value,
    });

    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
    } else {
      setCorreoError(false);
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      return;
    } else {
      setContraseñaError(false);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      clicLogin();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const clicLogin = () => {
    let error = false;
    if (!Values.Correo || Values.Correo == "") {
      setCorreoError(true);
      error = true;
    }
    if (!Values.Contraseña || Values.Contraseña == "") {
      setContraseñaError(true);
      error = true;
    }
    if (!error) {
      setOpenLoading(true);
      const info = {
        usuario: Values.Correo,
        contraseña: Values.Contraseña,
        key: KEY,
      };
      console.log(info);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(info),
      };
      fetch(BACKEND_URL + "acceso/autenticacion", requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setOpenLoading(false);
          if (response.resp.codigo == "200") {
            console.log(response);
            setLocalStorageJWT(response.resp.respuesta.token);
            setLocalStorage("img", response.resp.respuesta.img);
            setLocalStorage("tipo", response.resp.respuesta.tipo);
            window.location.reload();
          } else {
            console.log(response);
            setMensaje("Acceso incorrecto, verifica tus credenciales");
            setOpen(true);
          }
        })
        .catch((error) => {
          setOpenLoading(false);
          setMensaje(
            "Ha ocurrido un error al conectar con nuestros servidores, intenta mas tarde."
          );
          setOpen(true);
          return;
        });
    }
  };

  const handleRsCredencials = (obj) => {
    guardar(
      obj.nombre,
      obj.tipo,
      obj.idRS,
      obj.img,
      { correo: obj.correo },
      {},
      () => {}
    )
      .then((data) => {
        console.log(data);
        if (data.codigo == "200") {
          setLocalStorageJWT(data.respuesta.token);
          setLocalStorage("img", data.respuesta.img);
          window.location.href = "/";
          // Location.reload();
        } else {
          setMensaje("Acceso incorrecto, verifica tus credenciales");
          setOpen(true);
        }
      })
      .catch((data) => {
        console.log(data);
      });
  };

  React.useEffect(() => {
    document.title = `Login`;
  }, []);

  const CerrarSesion = () =>
    new Swal({
      title: "¿Estás seguro de querer cerrar sesión?",
      icon: "warning",
      showDenyButton: true,
      denyButtonText: "No, cancelar",
      confirmButtonText: "Si, estoy seguro",
      confirmButtonColor: "#c9a396",
      denyButtonColor: "#979797",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Cerrando sesión...",
          showConfirmButton: false,
          timer: 1500,
        }).then(clearStorageJWT);
      }
    });

  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(() => {
    getInfoUsuario().then(setTimeout(() => {}, 1500));
  }, []);

  return !isLoged ? (
    <>
      <Snackbar
        direction="left"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </Snackbar>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="primary" />
      </Backdrop>

      <div className=" p-4 m-auto  mt-5 " style={{ maxWidth: "450px" }}>
        <center>
          <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
            <img src={loogo} className="img-fluid  mb-5" />
          </Link>
        </center>
        <CardContent>
          <TextField
            error={CorreoError}
            fullWidth
            id="Correo"
            name="Correo"
            label="Correo Electrónico"
            variant="outlined"
            value={Values.Correo}
            onChange={handlInputChange}
          />
          <TextField
            fullWidth
            error={ContraseñaError}
            id="Contraseña"
            name="Contraseña"
            label="Contraseña"
            variant="outlined"
            className="mt-3"
            type="password"
            value={Values.Contraseña}
            onChange={handlInputChange}
            onKeyDown={handleKeyDown}
          />

          <center>
            <div className="mt-3">
              <Link
                style={{
                  fontFamily: "Avenir",
                  textTransform: "none",
                  color: "black",
                  fontWeight: 650,
                  textDecoration: "none",
                }}
                to="/restablecer-contrasena"
              >
                Olvidé mi contraseña
              </Link>
            </div>
          </center>

          <div className="mb-5 w-100"> </div>
          <Button
            fullWidth
            variant="contained"
            size="large"
            className="btn-michelle mt-3"
            onClick={clicLogin}
          >
            <Typography
              style={{
                fontFamily: "OxygenMedium",
                textTransform: "none",
              }}
            >
              Iniciar sesión
            </Typography>
          </Button>

          <div className="mb-3 w-100"> </div>

          <GoogleButton
            className="shadow-lg mt-3"
            handleRsCredencials={handleRsCredencials}
          />
          <div className="mb-3 w-100"> </div>
        
          {
            /*
              <FacebookButton
            className="shadow mt-3"
            handleRsCredencials={handleRsCredencials}
          />
            
            */
          }
          <Typography className="m-1 text-center mt-5 font-Oxygen">
            ¿Aún no tienes una cuenta?
          </Typography>
          <Link
            className="w-100"
            to={"/registro"}
            style={{
              textTransform: "none",
              textDecoration: "none",
            }}
          >
            <center>
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Regístrate aquí
              </Typography>
            </center>
          </Link>
        </CardContent>
      </div>
    </>
  ) : (
    <>
      <div className=" p-4 m-auto h-l00  mt-5 " style={{ maxWidth: "450px" }}>
        <center>
          <Typography className="m-1 text-center mt-5 font-Oxygen">
            Ya haz iniciado sesión
          </Typography>
          <div className="mb-5 w-100"> </div>
          <Button
            fullWidth
            variant="contained"
            size="large"
            className="btn-michelle mt-3"
            onClick={CerrarSesion}
          >
            <Typography
              style={{
                fontFamily: "OxygenMedium",
                textTransform: "none",
              }}
            >
              Cerrar sesión
            </Typography>
          </Button>
          <div className="mb-5 w-100"> </div>

          <Link to="/home" style={{ textDecoration: "none", color: "black" }}>
            <center>
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Ir a inicio
              </Typography>
            </center>
          </Link>
        </center>
      </div>
    </>
  );
}
