import React, { useEffect, useState } from "react";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { Link, useParams } from "react-router-dom";
import { getListaProductos, guardarFavorito } from "../Funciones/Productos";
import { Chip, Typography, Snackbar, Alert, Box, Button } from "@mui/material";
import Loading from "../../Includes/Loading";
import { styled } from "@mui/material/styles";
import { getOpcionesCategorias } from "../../Categorias/Funciones/Categorias";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BotonFavorito from "../../Lib/Favoritos/BotonFavorito";
import Offcanvas from "react-bootstrap/Offcanvas";
import FiltrosProductos from "./FiltrosProductos";
import { useWindowSize } from "../../Lib/Funciones Generales/WindowSize";
import Descuentos from "./Descuentos";

const Productos = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [Lista, setLista] = useState([]);
  const [mensaje, setMensaje] = useState("");
  const { id } = useParams();
  const [stateRow, setStateRow] = useState();

  const [showFiltrosProdMini, setShowFiltrosProdMini] = useState(false);
  const [categoria, setCategoria] = useState({ id, lista: [] });
  const [valuePrice, setValuePrice] = useState([0, 50000]);
  const { width, height } = useWindowSize();

  const getProductos = () => {
    setIsLoading(true);
    clearInterval(window.intervalo);
    const idCategoria = categoria.id == undefined ? 0 : categoria.id;
    getListaProductos(idCategoria, valuePrice[0], valuePrice[1], true)
      .then((resp) => {
        clearInterval(window.intervalo);
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        clearInterval(window.intervalo);
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getProductos();
    getOpcionesCategorias().then((resp) => {
      const list = JSON.parse(resp.data);
      setCategoria({ ...categoria, lista: list });
    });
  }, []);

  useEffect(() => {
    if (!IsLoading) {
      window.history.pushState(null, "", categoria.id);
      getProductos();
    }
  }, [categoria.id]);

  useEffect(() => {
    clearInterval(window.intervalo);
    if (!IsLoading) {
      window.intervalo = setInterval(() => {
        getProductos();
        clearInterval(window.intervalo);
      }, 1000);
    }
  }, [valuePrice]);

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <Offcanvas
        show={showFiltrosProdMini}
        onHide={() => setShowFiltrosProdMini(false)}
      >
        <Offcanvas.Header closeButton>
          <h5 className="font-OxygenBold mt-3 mb-3 ms-2">Filtrar productos</h5>
        </Offcanvas.Header>
        <Offcanvas.Body className="px-4">
          <FiltrosProductos
            absoluteRangeSlidePrecie={true}
            widthRangeSlider={"100%"}
            categoria={categoria}
            setCategoria={setCategoria}
            valuePrice={valuePrice}
            setValuePrice={setValuePrice}
          />
        </Offcanvas.Body>
      </Offcanvas>

      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="info"
          sx={{ width: "100%" }}
        >
          {mensaje}
        </Alert>
      </StyledSnackbar>
      <div className="espacio-header" />
      <div className="container pt-5">
        {width < 992 && (
          <div className="d-flex w-100 mb-3">
            <Button
              onClick={() => setShowFiltrosProdMini(true)}
              className="btn  font-OxygenBold  text-star ps-0 "
              variant="text"
            >
              <FilterAltIcon className="me-3" />
              Filtrar
            </Button>
          </div>
        )}

        <div className="d-flex justify-content-between flex-wrap">
          <div className="">
            <h2 className="font-PlayfairDisplay text-star">
              {IsLoading
                ? "Productos"
                : Lista.length != 0
                ? Lista[0].CATEGORIA
                : "Sin productos en esta categoría"}
            </h2>
          </div>
          <div className="">
            <Link
              className="w-100"
              to={"/"}
              style={{
                textTransform: "none",
                textDecoration: "none",
              }}
            >
              <Typography className="m-1 mt-0 text-center font-OxygenBold text-rosa  animated-text">
                Ir a inicio
              </Typography>
            </Link>
          </div>
        </div>

        <Box sx={{ display: "flex" }} className="mt-4">
          {width >= 992 && (
            <Box sx={{ flexGrow: 1 }} className="me-4 mt-4">
              <FiltrosProductos
                categoria={categoria}
                setCategoria={setCategoria}
                valuePrice={valuePrice}
                setValuePrice={setValuePrice}
              />
            </Box>
          )}

          <Box className="w-100 ps-4">
            <div className="row">
              {IsLoading ? (
                <div className="pt-5 w-100">
                  <Loading />
                </div>
              ) : (
                Lista.map((row) => (
                  <div className="p-2 p-md-3 mb-5 col-12 col-md-6 col-lg-4">
                    <Link
                      to={"/productos/view/" + row.ID}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div
                        className="w-100"
                        style={{
                          backgroundImage: `url(${
                            row.IMG != "" ? row.IMG : Skeleton2
                          })`,
                          // backgroundImage: `url(${ProdcutosArreglos})`,
                          minHeight: "370px",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                          position: "relative",
                        }}
                      >
                        <Chip
                          className="text-white px-3 position-absolute"
                          label={row.CATEGORIA}
                          style={{
                            backgroundColor: "#BFB7B9",
                            top: "330px",
                            left: "8px",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "5px",
                            right: "5px",
                          }}
                        >
                          <BotonFavorito
                            id={row.ID}
                            favorito={row.FAVORITO}
                            Lista={Lista}
                            setLista={setLista}
                            setStateRow={setStateRow}
                          />
                        </div>
                      </div>
                    </Link>
                    <h6 className="font-Oxygen mb-1 mt-3 text-star">
                      {row.DESC_CORTA}
                    </h6>
                    <h6 className="font-OxygenBold mb-4 mt-2 text-star">
                      <Descuentos
                        descuentos={row.DESCUENTOS}
                        precio={row.PRECIO_PUBLICO}
                      />
                    </h6>
                  </div>
                ))
              )}
            </div>
          </Box>
        </Box>
      </div>
      <div className="w-100 my-5 py-5" />
      <div className="w-100 my-5 py-5" />
    </>
  );
};
export default Productos;
