import React, { useEffect, useState } from "react";
import ImagenVideo from "../../assets/Home/ImagenVideo.webp";
import PlayBoton from "../../assets/PlayBoton.svg";
import { IconButton } from "@mui/material";

const Video = ({ isMobileOrTablet }) => {
  const [isPlay, setisPlay] = useState(false);
  /*const [embedCode, setEmbedCode] = useState(
    '<iframe  width="970px" height="700px" src="https://www.youtube.com/embed/_ivBl6fcJbI?autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
  );*/
  const [embedCode, setEmbedCode] = useState(
    `<iframe 
    style="width: 100%; height: 700px;" 
    src="https://www.youtube.com/embed/_ivBl6fcJbI?autoplay=1" 
    frameborder="0" allow="autoplay; encrypted-media"    
    allowfullscreen  />`
  );

  /*
      min-width: 400px;
      min-height: 400px;
      
      max-width: 400px;
      max-height: 400px;

      width: max-content;
      height: max-content;

      width: min-content;
      height: min-content;
  */

  return isPlay ? (
    <div
      dangerouslySetInnerHTML={{ __html: embedCode }}
      style={
        {
          //width: "930px",
          //height: "700px",
          //background: "blue",
          //padding: "2",
          //maxWidth: "2760px",
          //minWidth: "400px",
          //maxHeight: "700px",
          //minHeight: "400px",
          //width: "100%",
          //height: "100%",
        }
      }
      className="p-1 position-relative frame-video-info"
    ></div>
  ) : (
    <>
      <div
        className="p-1 position-relative   frame-video-info"
        data-aos="zoom-in"
        style={{
          //maxWidth: "2760px",
          //minWidth: "300px",
          //width: "2760px",
          //maxHeight: "700px",
          //minHeight: "300px",
          //height: "700px",
          //width: "100%",
          //height: "100%",
          backgroundImage: `url("${ImagenVideo}")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          //background: "blue",
        }}
      >
        {/*<img
            src={ImagenVideo}
            className="img-fluid"
            style={{ height: "100%", width: "100%" }}
          />*/}

        {/*<div
            className="img-fluid"
            style={{
              backgroundImage: `url("${ImagenVideo}")`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              minHeight: "400px",
            }}
          />*/}
        <div
          style={{
            position: "absolute",
            right: "0%",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <center>
            <IconButton
              aria-label="Close "
              className="m-0 m-auto container-animacion"
              onClick={() => {
                setisPlay(true);
              }}
            >
              <img
                src={PlayBoton}
                className="img-fluid"
                style={{ width: "45px" }}
              />
            </IconButton>
          </center>
        </div>
      </div>
    </>
  );
};
export default Video;
