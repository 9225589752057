import React, { useEffect, useState } from "react";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import ProdcutosArreglos from "../../assets/Home/ProdcutosArreglos.webp";
import FavoritosOFF from "../../assets/FavoritosOFF.svg";
import Skeleton2 from "../../assets/Skeleton2.webp";
import { Link } from "react-router-dom";
import { getListaRandom } from "../Funciones/Productos";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";
import { Chip, IconButton } from "@mui/material";
import BotonFavorito from "../../Lib/Favoritos/BotonFavorito";
import Descuentos from "./Descuentos";

const CarruselRandom = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);
  const [stateRow, setStateRow] = useState();

  // const history = useHistory();

  const handleElementClick = (id) => {
    // history.push(`/productos/view/${id}`);
  };
  const getRandom = () => {
    setIsLoading(true);
    getListaRandom("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getRandom();
  }, []);

  return (
    <>
      <Splide
        options={{
          rewind: true,
          arrows: true,
          perPage: 3,
          breakpoints: {
            997: {
              perPage: 2,
            },
            700: {
              perPage: 2,
            },
            500: {
              perPage: 2,
            },
            400: {
              perPage: 1,
            },
          },
        }}
        aria-label="React Splide Example"
      >
        {Lista.map((row) => (
          <SplideSlide>
            <div className="p-2">
              <a
                className="w-100"
                href={"/productos/view/" + row.ID}
                style={{
                  textTransform: "none",
                  textDecoration: "none",
                }}
              >
                <div
                  className="w-100"
                  style={{
                    cursor: "pointer",
                    backgroundImage: `url(${
                      row.IMG != "" ? row.IMG : Skeleton2
                    })`,
                    minHeight: "370px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <Chip
                    className="text-white px-3 position-absolute"
                    label={row.CATEGORIA}
                    style={{
                      backgroundColor: "#BFB7B9",
                      top: "330px",
                      left: "40px",
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                    }}
                  >
                    <BotonFavorito
                      id={row.ID}
                      favorito={row.FAVORITO}
                      Lista={Lista}
                      setLista={setLista}
                      setStateRow={setStateRow}
                    />
                  </div>
                </div>
              </a>

              <h6 className="font-Oxygen mb-1 mt-3 text-star">
                {row.DESC_CORTA}
              </h6>
              <h6 className="font-OxygenBold mb-4 mt-2 text-star">
                <Descuentos
                  descuentos={row.DESCUENTOS}
                  precio={row.PRECIO_PUBLICO}
                />
              </h6>
            </div>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};
export default CarruselRandom;
