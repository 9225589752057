import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Loading from "../../../Includes/Loading";
import { getInfoMonedero } from "../../Funciones/Perfiles";
import withReactContent from "sweetalert2-react-content";
import AddIcon from "@mui/icons-material/Add";

import ModalCreditos from "./ModalCreditos";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";

const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "100%",
  backgroundColor: "#EBE1D6",
  borderRadius: "10px",
});

const StyledCardMedia = styled(CardMedia)({
  paddingTop: "56.25%", // 16:9 aspect ratio
});

const DatosCreditos = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [creditos, setCreditos] = useState([]);
  const [showModalCreditos, SetshowModalCreidtos] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [saldo, setSaldo] = useState("0");

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    GetInfoCreditos();
  }, []);

  const GetInfoCreditos = () => {
    setIsLoading(true);
    getInfoMonedero()
      .then((resp) => {
        let list = JSON.parse(resp.data);
        if (resp.saldo) {
          setSaldo(resp.saldo);
        }
        setCreditos(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setCreditos([]);
        setIsLoading(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalCreditos ? (
        <ModalCreditos
          GetInfoCreditos={GetInfoCreditos}
          SetshowModalCreidtos={SetshowModalCreidtos}
        />
      ) : (
        ""
      )}

      {IsLoading ? (
        <Row className="px-3">
          <Col sm={12} className="p-3">
            <Loading />
          </Col>
        </Row>
      ) : (
        <div style={{ maxWidth: "950px" }}>
          <Row className="p-3">
            <div className="col-12 text-end">
              <Button
                onClick={() => SetshowModalCreidtos(true)}
                className="btn shadow-none  btn-michelle font-OxygenMedium  py-2 px-4 "
                variant="contained"
                style={{ max: "400px" }}
              >
                <span className={"px-2 font-OxygenMedium"}>
                  <AddIcon className="me-2" />
                  Agregar
                </span>
              </Button>
            </div>

            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={4}
              sx={{ width: "100%" }}
            >
              <StyledCard
                sx={{ width: 290, flexShrink: 0, height: 210, maxHeight: 210 }}
                className="mt-5"
              >
                <CardContent sx={{ position: "relative" }}>
                  <Typography gutterBottom>
                    <p>
                      <strong> Flower </strong>
                      <span>Wallet</span>
                    </p>
                  </Typography>
                  <Typography style={{ textAlign: "right", fontSize: "30px" }}>
                    {formatMoneda(parseFloat(saldo))}
                  </Typography>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "-84px",
                      left: "-15px",
                      width: "120px",
                      height: "120px",
                      borderRadius: "100%",
                      backgroundColor: "#C9A396",
                      border: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        top: "1px",
                        position: "absolute",
                        left: "73px",
                        width: "60px",
                        height: "60px",
                        borderRadius: "100%",
                        border: "2px solid #FFFFFF",
                      }}
                    ></div>
                  </div>
                </CardContent>
              </StyledCard>

              <div style={{ width: "100%" }}>
                <Typography variant="h6" gutterBottom className="mt-5">
                  Historial
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "#F7F7F7",
                        border: "none",
                        borderBottom: "unset",
                      }}
                    >
                      <TableCell sx={{ borderBottom: "none" }}>Fecha</TableCell>
                      <TableCell sx={{ borderBottom: "none" }}>
                        Concepto
                      </TableCell>

                      <TableCell align="right" sx={{ borderBottom: "none" }}>
                        Monto
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {creditos.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {item.FECHA_DE_COMPRA}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }}>
                          {item.TRASACCION}
                        </TableCell>
                        <TableCell sx={{ borderBottom: "none" }} align="right">
                          {formatMoneda(parseFloat(item.MONTO_COMPRADO))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </Stack>
          </Row>
        </div>
      )}
    </>
  );
};

export default DatosCreditos;
