import {
  Alert,
  Avatar,
  Snackbar,
  TextField,
  Stack,
  Button,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import {
  guardar,
  getInfoPerfil,
  actualizarContrasena,
} from "../Funciones/Perfiles";
import Loading from "../../Includes/Loading";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import ModalCargarImagen from "../../Lib/Imagen/Componentes/ModalCargarImagen";
import AvatarFotoPerfil from "../../assets/AvatarFotoPerfil.svg";
import { IMG } from "../../Lib/Funciones Generales/Constantes";
import { getLocalStorage } from "../../Context/Storage";

const MySwal = withReactContent(Swal);

const DatosPerfil = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const fnDevolverImagen = (img) => {
    let random = "";
    if (Values.id != "0" && Values.id != 0) {
      random = "?" + Math.floor(Math.random() * 1000000);
    }
    setValues({ ...Values, img: img + random });
  };

  const { id } = useParams();
  const navigate = useNavigate();
  const [IsLoading, setIsLoading] = useState(true);
  const [showPasswordUpdate, setshowPasswordUpdate] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [perfilType, setPerfilType] = useState(true);
  const [Values, setValues] = useState({
    id: id ?? "0",
    nombre: "",
    razon_social: "",
    regimen_fiscal: "",
    rfc: "",
    direccion: "",
    cp: "",
    correo: "",
    telefono: "",
    contrasena_actual: "",
    nueva_contrasena: "",
    confirmar_nueva_contrasena: "",
    apellido: "",
    img: AvatarFotoPerfil,
  });

  const [Errores, setErrores] = useState({
    nombre: false,
    razon_social: false,
    regimen_fiscal: false,
    rfc: false,
    direccion: false,
    cp: false,
    correo: false,
    telefono: false,
    contrasena_actual: false,
    nueva_contrasena: false,
    confirmar_nueva_contrasena: false,
    apellido: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target, buscador }) => {
    const Name = target ? target.name : buscador.name;
    const Value = target ? target.value : buscador.value;
    let NewValue = {
      ...Values,
      [Name]: Value,
    };

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    document.title = "Perfil";
    getInfoPerfil(AvatarFotoPerfil)
      .then((resp) => {
        console.log(resp);

        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarCliente = () => {
    setIsGuardando(true);
    guardar(Values, Errores, setErrores, perfilType)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const ActualizarContrasena = () => {
    setIsGuardando(true);
    actualizarContrasena(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {
            setshowPasswordUpdate(false);
            setValues({
              ...Values,
              contrasena_actual: "",
              nueva_contrasena: "",
              confirmar_nueva_contrasena: "",
            });
          });
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  const img_url =
    Values.img == AvatarFotoPerfil ? AvatarFotoPerfil : Values.img;

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>

      {IsLoading ? (
        <Row className="px-3">
          <Col sm={12} className="p-3">
            <Loading />
          </Col>
        </Row>
      ) : (
        <>
          {showPasswordUpdate ? (
            <Row className="p-3">
              <Col sm={12} className="px-3">
                <h6 className="text-mutted font-AvenirBold">
                  {" "}
                  Cambio de contraseña{" "}
                </h6>
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="contrasena_actual"
                  label="Contraseña actual"
                  variant="outlined"
                  helperText={
                    Errores.contrasena_actual
                      ? "La contraseña es incorrecta"
                      : ""
                  }
                  value={Values.contrasena_actual}
                  error={Errores.contrasena_actual}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="nueva_contrasena"
                  label="Nueva contraseña"
                  variant="outlined"
                  helperText={
                    Errores.nueva_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.nueva_contrasena}
                  error={Errores.nueva_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>
              <Col sm={12} md={4} className="p-3">
                <TextField
                  fullWidth
                  name="confirmar_nueva_contrasena"
                  label="Repite la contraseña"
                  variant="outlined"
                  helperText={
                    Errores.confirmar_nueva_contrasena
                      ? "Las contraseñas no coinciden"
                      : ""
                  }
                  value={Values.confirmar_nueva_contrasena}
                  error={Errores.confirmar_nueva_contrasena}
                  onChange={handlInputChange}
                  type="password"
                />
              </Col>

              <Stack
                className="p-3"
                spacing={2}
                direction={{ xs: "column", sm: "row" }}
                style={{}}
              >
                <LoadingButton
                  loading={IsGuardando}
                  loadingPosition="start"
                  disabled={IsLoading}
                  onClick={() => ActualizarContrasena()}
                  className="btn btn-michelle font-OxygenMedium  py-2 px-4 "
                  variant="contained"
                  xs={{ with: "100$" }}
                >
                  <span className={IsGuardando ? "px-4" : "px-2"}>
                    Actualizar contraseña
                  </span>
                </LoadingButton>

                <Button
                  onClick={() => setshowPasswordUpdate(false)}
                  className="btn btn-cancelar font-AvenirMedium py-2 px-4"
                  variant="outlined"
                >
                  Regresar
                </Button>
              </Stack>
            </Row>
          ) : (
            <div style={{ maxWidth: "950px" }}>
              <Row className="p-3">
                <Col
                  sm={12}
                  className="d-flex   align-items-center align-content-center justify-content-star "
                >
                  <div className="d-flex flex-wrap align-items-center  justify-content-center">
                    <Avatar
                      className="shadow"
                      sx={{ width: 120, height: 120 }}
                      src={Values.img}
                    />
                    <strong
                      className="w-100 text-center px-0 ms-2 pt-3"
                      style={{ color: "#C9A396", cursor: "pointer" }}
                      onClick={handleOpenModal}
                    >
                      Subir
                    </strong>
                  </div>
                  <div className="d-flex flex-wrap align-items-center justify-content-center aligin-self-center">
                    <h6 className="w-100 mb-1 px-0 ms-2  font-OxygenBold">
                      {Values.nombre + " " + Values.apellido}
                    </h6>
                    <h6 className="w-100 mb-0 r px-0 ms-2 font-Oxygen">
                      {Values.correo}
                    </h6>
                  </div>
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="nombre"
                    label="Nombre"
                    variant="outlined"
                    value={Values.nombre}
                    error={Errores.nombre}
                    onChange={handlInputChange}
                  />
                </Col>
                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="apellido"
                    label="Apellidos"
                    variant="outlined"
                    value={Values.apellido}
                    error={Errores.apellido}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="correo"
                    label="Correo electrónico"
                    variant="outlined"
                    helperText={Errores.correo ? "Correo incorrecto" : ""}
                    value={Values.correo}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col sm={12} md={6} className="p-3">
                  <TextField
                    fullWidth
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    helperText={Errores.telefono ? "Teléfono inválido" : ""}
                    value={Values.telefono == 0 ? "" : Values.telefono}
                    onChange={handlInputChange}
                  />
                </Col>

                <Col
                  sm={12}
                  md={6}
                  className="p-3    d-flex   justify-content-star align-items-center"
                >
                  <Link
                    className="aligin-self-center"
                    onClick={(event) => {
                      event.preventDefault();
                      setshowPasswordUpdate(true);
                    }}
                    style={{ textDecorationLine: "none" }}
                  >
                    <strong
                      className="w-100  px-0   animated-text"
                      style={{ color: "#C9A396", cursor: "pointer" }}
                    >
                      Cambiar contraseña
                    </strong>
                  </Link>
                </Col>

                <Col sm={12} md={6} className="p-3 d-flex">
                  <LoadingButton
                    loading={IsGuardando}
                    loadingPosition="start"
                    disabled={IsLoading}
                    onClick={() => GuardarCliente()}
                    className="btn w-100 btn-michelle font-OxygenMedium  py-2 px-4 "
                    variant="contained"
                    xs={{ with: "100$" }}
                  >
                    <span className={IsGuardando ? "px-4" : "px-2"}>
                      Actualizar datos
                    </span>
                  </LoadingButton>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}

      {openModal && (
        <ModalCargarImagen
          id={Values.id}
          tipo={
            getLocalStorage("tipo") == "EMPRESA"
              ? IMG.TIPO_EMPRESAS
              : IMG.TIPO_USUARIOS
          }
          Titulo="Cargar imagen de perfil"
          mostrar={openModal}
          imgUrl={Values.img}
          fnCerrar={handleCloseModal}
          fnDevolverImagen={fnDevolverImagen}
        />
      )}
    </>
  );
};

export default DatosPerfil;
