import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Loading from "../../Includes/Loading";
import { getInfoCompra } from "../Funciones/Orden";
import { formatMoneda } from "../../Lib/Funciones Generales/Generales";

function PagoSuccess() {
  const { id } = useParams();
  const [Values, SetValues] = useState([]);
  const [IsLoading, setIsLoading] = useState(true);

  const getInfo = () => {
    getInfoCompra(id)
      .then((resp) => {
        SetValues(resp);
        setIsLoading(false);
      })
      .catch((resp) => {
        alert("Erorr");
        SetValues([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  console.log(Values);

  return (
    <>
      <div className="espacio-header" />
      <div className="container-fluid px-0">
        <div className="w-100 my-4 py-4" />

        {IsLoading ? (
          <div className="col-12 my-5">
            <Loading />
          </div>
        ) : (
          <>
            {Values ? (
              <>
                <h2
                  className="text-black font-PlayfairDisplay fw-bolder text-center w-100"
                  style={{ fontWeight: "100" }}
                >
                  ¡Tu pedido fué realizado con éxito!
                </h2>
                <div className="d-flex justify-content-center">
                  <div
                    className="text-center mt-5"
                    style={{ maxWidth: "400px" }}
                  >
                    <div
                      className="p-5"
                      style={{
                        backgroundColor: "#f7f7f7",
                      }}
                    >
                      <h5
                        className="text-black font-PlayfairDisplay text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        No. de pedido
                      </h5>
                      <p className="font-Oxygen text-center">
                        {Values.FOLIO_VENTA}
                      </p>
                      <h5
                        className="text-black font-PlayfairDisplay text-center"
                        style={{ fontWeight: "bold" }}
                      >
                        Fecha de entrega
                      </h5>
                      <p className="font-Oxygen text-center">
                        {Values.FECHA_DE_ENTREGA} <br />
                        Entre las {Values.ENTREGA_INICIO} y las{" "}
                        {Values.ENTREGA_FIN} Hrs
                      </p>
   {
                        Values.PROMO_APLICADA!=""?
                      <h5>
                      Felicidades!
                      </h5>: ""
}

  <p className="font-Oxygen text-center">
                       {
                        Values.PROMO_APLICADA!=""?
                        <> {  "Tu compra te ha generado"}
                        <b>   {formatMoneda((parseFloat( Values.TOTAL)*115)/100)} </b>
                    { " Flower Wallet" }
                        </> 
                    : ""
                       }
                      </p>
 

                      <p className="font-Oxygen text-center mt-5">
                        Podrás consultar el estatus de tu pedido en{" "}
                        <a
                        href="/perfil"
                          style={{ cursor: "pointer" }}
                          className="text-rosa animated-text"
                        >
                          Mi perfil
                        </a>
                      </p>
                    </div>

                    <a
                      className=""
                      href="/"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button
                        className="btn btn-michelle mt-4 w-100 font-OxygenMedium  py-2 px-4 "
                        variant="contained"
                      >
                        <span>Volver a la tienda </span>
                      </Button>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <> </>
            )}
          </>
        )}

        <div className="w-100 my-4 py-4" />
      </div>
    </>
  );
}

export default PagoSuccess;
