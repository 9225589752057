import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { getLista } from "../Funciones/Categorias";

const CarruselCategorias = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [Lista, setLista] = useState([]);

  const getDireciones = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        console.log(list);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getDireciones();
  }, []);

  return (
    <>
      <Splide
        options={{
          rewind: true,
          arrows: false,
          perPage: 3,
          breakpoints: {
            640: {
              perPage: 2,
            },
            400: {
              perPage: 1,
            },
          },
        }}
        aria-label="React Splide Example"
      >
        {Lista.map((row) => (
          <SplideSlide>
            <Link
              to={"/productos/cat/" + row.ID}
              style={{ textDecoration: "none", color: "black" }}
            >
              <div className="p-2">
                <div
                  className="w-100 container-categoria"
                  style={{
                    backgroundImage: `url(${row.IMG != "" ? row.IMG : ""})`,
                    minHeight: "400px",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    cursor: "pointer",
                    transition: " all 0.5s ease",
                  }}
                ></div>
                <h4 className="font-PlayfairDisplay mb-4 mt-2 text-center">
                  {row.NOMBRE}
                </h4>
              </div>
            </Link>
          </SplideSlide>
        ))}
      </Splide>
    </>
  );
};
export default CarruselCategorias;
