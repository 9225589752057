import Login from "./Login/componentes/login";
import { useEffect, useState } from "react";
import {
  getLocalStorageJWT,
  setLocalStorageJWT,
  clearStorageJWT,
  getLocalStorage,
} from "./Context/Storage";
//import { Helmet } from "react-helmet";
import StartLoad from "./Includes/StartLoad";
import Menu from "./Includes/Menu";

const App = () => {
  const [isLoged, setIsLoged] = useState(false);
  const [load, setLoad] = useState(true);
  const [tipo, setTipo] = useState("");
  const getInfoUsuario = async () => {
    if (getLocalStorageJWT() != "") {
      setIsLoged(true);
    }
  };
  useEffect(() => {
    setLoad(true);
    getInfoUsuario().then(
      setTimeout(() => {
        setLoad(false);
        setTipo(getLocalStorage("tipo"));
        /*console.log(tipo);
        if (tipo == "MANAGER") {
          window.location.href = "manager/";
        }*/
        
      }, 1500)
    );
  }, []);
  return load ? <StartLoad /> : <Menu /> ;
};
export default App;
