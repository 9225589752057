import { Alert, Divider, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button, Stack } from "react-bootstrap";
import { Col, Row } from "react-bootstrap";
import Loading from "../../Includes/Loading";
import AddIcon from "@mui/icons-material/Add";

import withReactContent from "sweetalert2-react-content";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import ModalDireccion from "../../Lib/Direcciones/Componentes/ModalDireccion";
import { getLista } from "../../Lib/Direcciones/Funciones/Direcciones";
import AccEditar from "./../../assets/AccEditar.svg";
import AccEliminar from "./../../assets/AccEliminar.svg";
import ModalSelectSucursal from "../../Lib/Direcciones/Componentes/ModalSelectSucursal";

const MySwal = withReactContent(Swal);

const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
  ({ theme }) => ({
    "& .MuiSnackbar-root": {
      top: theme.spacing(15),
    },
  })
);

const Sucursal = ({ sucursal, setSucursal }) => {
  const [IsLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [showModalDireccion, SetshowModalDireccion] = useState(false);
 
  
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // getDireciones();
  }, []);


  /*
  const getDireciones = () => {
    setIsLoading(true);
    getLista("")
      .then((resp) => {
        let list = JSON.parse(resp.data);
        setLista(list);
        setIsLoading(false);
      })
      .catch((resp) => {
        setLista([]);
        setIsLoading(false);
      });
  };
*/
 

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {showModalDireccion ? (
        <ModalSelectSucursal
          SetshowModalDireccion={SetshowModalDireccion}
          setSucursal={setSucursal} 
        />
      ) : (
        ""
      )}

      {IsLoading ? (
        <Col sm={12} className="pt-5">
          <Loading />
        </Col>
      ) : (
        <div style={{ maxWidth: "950px" }}>
          <div
            className="pb-0 px-3 px-md-4 pt-3  mt-3"
            style={{ backgroundColor: "#F7F7F7" }}
          >
            <div className="row">
              <div className="col-12">
                <h6 className="font-OxygenBold">
                  {sucursal.id == 0
                    ? ""
                    : sucursal.nombre}
                </h6>
              </div>

              <div className="col-12  mt-3 mt-md-1">
                <small>
                  <p className="font-OxygenBold my-1">Sucursal</p>
                  <p className="font-OxygenLight my-1">
                    {sucursal.id == 0
                      ? "Aún no seleccionas una de nuestras sucursales"
                      : sucursal.domicilio}
                  </p>
                </small>
              </div>

              <div className="col-12 text-end">
                <Stack
                  className="w-100 mt-2"
                  spacing={4}
                  direction="row"
                  justifyContent="end"
                >
                  {
                    <Button
                      className="btn-accion font-OxygenBold"
                      variant="text"
                      onClick={() => { 
                        SetshowModalDireccion(true);
                      }}
                    >
                      <img
                        src={AccEditar}
                        className="me-2 mb-1"
                        style={{ width: "15px" }}
                      />
                      {sucursal.id == 0 ? "Seleccionar sucursal" : "Editar"}
                    </Button>
                  }
                </Stack>
              </div>
            </div>
          </div> 
        </div>
      )}
    </>
  );
};

export default Sucursal;
