import {
    Alert,
    Avatar,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Rating,
    Snackbar,
    TextField
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { getListaComentarios, guardarProductoComentario } from "../../Funciones/Productos";
import Loading from "../../../Includes/Loading";
import DescripcionReserñas from "../DescripcionReserñas";

import { styled } from "@mui/material/styles";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getLocalStorage } from "../../../Context/Storage";
const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);

const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
        color: "#c9a396",
    },
    "& .MuiRating-iconHover": {
        color: "#ff3d47",
    },
});

const ModalReseñasEditar = ({
    setShowDialogReseñaEditar
    , id_producto
    , comentarioEditar
    , setComentarioEditar
    , setReseñas
    , resenas
    , resenasModal
    , setReseñasModal
}) => {


    console.log("COMENTARIO: ", comentarioEditar)

    const [open, setOpen] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const IMG_PERFIL = getLocalStorage("img")


    const GuardarComentario = () => {
        new Swal({
            title: "¿Estás seguro de modificar esta reseña?",
            text: "esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            if (isConfirm.isConfirmed) {
                guardarProductoComentario(id_producto, comentarioEditar.COMENTARIO, comentarioEditar.CALIFICACION, comentarioEditar.ID)
                    .then((resp) => {


                        if (resp.data.codigo == "200") {
                            MySwal.fire({
                                title: "Correcto",
                                html: resp.data.mensaje,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                // const datos = JSON.parse(resp.data.respuesta)
                                const COMENTARIO = {...comentarioEditar, COMENTARIO: comentarioEditar.COMENTARIO.replaceAll("\n", "<br>")}
                                const index = resenas.findIndex(x => x.ID == comentarioEditar.ID)
                                if (index != -1) {
                                    resenas[index] = COMENTARIO;
                                }
                                setReseñas([...resenas])

                                if (resenasModal) {
                                    if (Array.isArray(resenasModal)) {
                                        const index = resenasModal.findIndex(x => x.ID == comentarioEditar.ID)
                                        if (index != -1) {
                                            resenasModal[index] = COMENTARIO;
                                        }
                                        setReseñasModal([...resenasModal])
                                    }
                                }
                            });
                            


                        } else {
                            setMensaje(resp.data.mensaje);
                            setOpen(true);
                        }
                    })
                    .catch((data) => {
                        // alert(data.mensaje);
                        setMensaje(data.mensaje);
                        setOpen(true);
                    });
            }
        });
    }

    return (
        <>
            <StyledSnackbar
                direction="right"
                open={open}
                autoHideDuration={6000}
                onClose={() => setOpen(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setComentarioEditar(null);
                        setShowDialogReseñaEditar(false);
                    }}
                >
                    <CloseIcon size="lg" />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder text-center f-12 mt-2"> Editar reseña</h6>
                </DialogTitle>
                <DialogContent>
                    <div className="d-flex justify-content-between flex-wrap pe-2 pt-2">
                        <Avatar
                            className="shadow mt-2 ms-2"
                            sx={{ width: 60, height: 60 }}
                            src={IMG_PERFIL}
                        />
                        <div style={{ width: 'calc(100% - 90px)' }}>
                            <div className="col-12   ">
                                <TextField
                                    onChange={({ target }) => {
                                        setComentarioEditar({ ...comentarioEditar, COMENTARIO: target.value });
                                    }}
                                    value={comentarioEditar.COMENTARIO}
                                    fullWidth
                                    multiline={true}
                                    maxRows={3}
                                    minRows={3}
                                    size="small"
                                    name="envia"
                                    label="Deja un comentario"
                                    placeholder="(Máx 300)"
                                    variant="outlined"
                                    inputProps={{ maxLength: 300 }}
                                />
                            </div>





                            <div className="d-flex justify-content-between w-100 mt-2">
                                <StyledRating
                                    onChange={({ target }) => {
                                        setComentarioEditar({ ...comentarioEditar, CALIFICACION: target.value });
                                    }}
                                    name="customized-color"
                                    defaultValue={0}
                                    precision={1}
                                    size="small"
                                    value={comentarioEditar.CALIFICACION}
                                />


                                <Button
                                    onClick={GuardarComentario}
                                    loadingPosition="start"
                                    className="btn btn-michelle  font-OxygenBold  py-2 px-4 "
                                    variant="contained"
                                >
                                    <span>Enviar</span>
                                </Button>

                            </div>


                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ModalReseñasEditar