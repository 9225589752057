import { Col, Row } from "react-bootstrap";
import { SERVER_IMG } from "../Context/backend";

const BANNER = SERVER_IMG + "BannerNosotros.webp";
const LOGO = SERVER_IMG + "LogoNosotros.svg";
const FOTO_1 = SERVER_IMG + "FotoDeEmpresa.webp";
const FOTO_2 = SERVER_IMG + "FotoMision.webp";
const FOTO_3 = SERVER_IMG + "Foto Vision.webp";
const FOTO_4 = SERVER_IMG + "FotoVision2.webp";

const urls = {
  BannerNosotros1:
    "https://florcreativa.blob.core.windows.net/imagenes/BannerNosotros1.webp",
};

const NosotrosRote = () => {
  return (
    <>
      <div className="espacio-header" />
      {/* BANNER */}
      <div
        className="banner-nosotros"
        style={{ backgroundImage: `url("${urls.BannerNosotros1}")` }}
      >
        <div
          className="container d-flex flex-wrap  align-items-center"
          style={{ height: 500 }}
        >
          <div className="d-flex justify-content-start align-items-start flex-column flex-grow-1 text-white">
            <div className="d-flex justify-content-start ">
              <h2
                className="font-PlayfairDisplay "
                style={{ fontSize: "2.5rem" }}
              >
                Acerca
              </h2>
              <h2
                className="font-PlayfairDisplayItalic mb-3  ms-2"
                style={{ fontSize: "2.5rem" }}
              >
                de
              </h2>
            </div>
            <h2
              className=" mb-4 w-100 "
              style={{
                fontWeight: "bold",
                fontFamily: "PlayfairDisplay",
                fontSize: "2.5rem",
              }}
            >
              nosotros
            </h2>
          </div>

          {/*<div className="flex-grow-1">
            <h4
              className="font-PlayfairDisplay text-white"
              style={{ fontSize: "2rem" }}
            >
              Acerca
            </h4>
            <h4
              className="font-PlayfairDisplayItalic text-white"
              style={{ fontSize: "2rem" }}
            >
              de
            </h4>
            <h4
              className="font-PlayfairDisplay text-white"
              style={{ fontSize: "4rem" }}
            >
              nosotros
            </h4>
            </div>*/}
          <div className="">
            <img className="" src={LOGO} />
          </div>
        </div>
      </div>
      {/* FIN BANNER */}

      {/* PARTE 1 */}
      <div
        className="container-fluid"
        style={{
          backgroundColor: "#212529",
          paddingTop: "8rem",
          paddingBottom: "18rem",
        }}
      >
        <div className="d-flex container">
          <div
            className="d-flex align-items-center mb-auto mt-5 pt-5 d-none d-md-flex"
            style={{ transform: "rotate(-90deg)" }}
          >
            <h5
              className="font-PlayfairDisplay text-white"
              style={{ width: "100px" }}
            >
              (About Us)
            </h5>
            <div
              className="mb-1 ms-2"
              style={{ height: "1px", backgroundColor: "#FFF", width: "50px" }}
            />
          </div>
          <div className="container">
            <Row className="w-100 mx-0">
              <Col sm={12} md={6}>
                <h4
                  className="font-PlayfairDisplay text-white mb-5"
                  style={{ fontSize: "2rem" }}
                >
                  Quiénes somos Nosotros
                </h4>
                <p className="font-Oxygen text-white texto-nosotros">
                  Somos un estudio floral creativo, dedicado a la creación de
                  experiencias personalizadas a través del amor por el diseño
                  floral.
                </p>
                <p className="font-Oxygen text-white texto-nosotros">
                  Hacemos de nuestra sensibilidad para captar la idea de
                  nuestros clientes una de nuestras mayores virtudes;
                  encontramos en el arte nuestro referente inmediato de
                  inspiración echando mano de texturas, colores y formas
                  encontradas en las flores para revestir y transformar
                  espacios.
                </p>
                <p className="font-Oxygen text-white texto-nosotros">
                  Nuestras creaciones llevan el sello impreso del ímpetu, la
                  emotiva creatividad, el enfoque técnico y la estricta
                  rigurosidad de la infraestructura técnica.
                </p>

                <h4
                  className="font-PlayfairDisplay text-white my-5"
                  style={{ fontSize: "2rem" }}
                >
                  ¿Qué hacemos?
                </h4>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Diseñamos y creamos espacios y atmósferas{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Dirección en ambientación conceptual{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Dirección de arte y campañas{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Workshops{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Arreglos florales y productos personalizados{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Elaboración creativa de productos propios{" "}
                </p>
                <p className="font-Oxygen text-white texto-nosotros my-1">
                  · Renta de equipo de ambientación para eventos{" "}
                </p>
              </Col>
              <Col sm={12} md={6} className="pt-5 mt-5">
                <img src={FOTO_1} className="img-fluid px-0 px-md-5" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
      {/* FIN PARTE 1 */}

      {/* PARTE 2 */}

      <div
        className="container"
        style={{ marginTop: "-12rem", marginBottom: "7rem" }}
      >
        <Row className="w-100 mx-0">
          <Col sm={12}>
            <Row className="w-100 mx-0">
              <Col
                sm={12}
                md={6}
                className="p-3 d-flex justify-content-center"
                style={{ minHeight: "600px" }}
              >
                <div
                  className="img-nosotros"
                  style={{ backgroundImage: `url("${FOTO_2}")` }}
                />
              </Col>
              <Col sm={12} md={6} className=" mt-auto p-3">
                <div className=" MuiLinearProgress-bar3Indeterminate p-5">
                  <div className="d-flex flex-wrap align-items-center">
                    <h4
                      className="font-PlayfairDisplay"
                      style={{ fontSize: "3.5rem" }}
                    >
                      Misión
                    </h4>
                    <h4
                      className="font-PlayfairDisplay mt-3 ms-2"
                      style={{ fontSize: "1.5rem" }}
                    >
                      (Mission)
                    </h4>
                  </div>
                  <p className="font-Oxygen texto-nosotros my-1">
                    Conectar, escuchar y respetar la esencia de nuestros
                    clientes. Para poder ofrecer nuestros productos y servicios
                    llenos de experiencias, emociones y diseño creativo con la
                    mejor calidad para estar presentes en los momentos más
                    importantes y trascendentes de la vida de las personas,
                    garantizando la satisfacción de nuestros clientes.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>

          <Col sm={12}>
            <Row className="w-100 mx-0">
              <Col
                sm={12}
                md={6}
                lg={3}
                className="p-3 d-flex justify-content-center"
                style={{ minHeight: "320px" }}
              >
                <div
                  className="img-nosotros"
                  style={{ backgroundImage: `url("${FOTO_3}")` }}
                />
              </Col>
              <Col
                sm={12}
                md={6}
                lg={3}
                className="p-3 d-flex justify-content-center"
                style={{ minHeight: "320px" }}
              >
                <div
                  className="img-nosotros"
                  style={{ backgroundImage: `url("${FOTO_4}")` }}
                />
              </Col>
              <Col
                sm={12}
                md={12}
                lg={6}
                className="p-3 d-flex"
                style={{ maxHeight: "320px" }}
              >
                <div className=" MuiLinearProgress-bar2Indeterminate p-5 ">
                  <div className="d-flex flex-wrap align-items-center">
                    <h4
                      className="font-PlayfairDisplay text-white"
                      style={{ fontSize: "3.5rem" }}
                    >
                      Visión
                    </h4>
                    <h4
                      className="font-PlayfairDisplay text-white mt-3 ms-2"
                      style={{ fontSize: "1.5rem" }}
                    >
                      (Vision)
                    </h4>
                  </div>
                  <p className="font-Oxygen text-white texto-nosotros m-0">
                    Ser la empresa líder en el ámbito floral de una manera
                    creativa, disruptiva, innovadora, vanguardista y responsable
                    para un posicionamiento a nivel mundial. Así como brindar
                    servicios florales y renta de estructuras decorativas.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {/* FIN PARTE 2 */}
    </>
  );
};

export default NosotrosRote;
