import React, { useState, useEffect } from "react";
import {
  Alert,
  Snackbar,
  FormGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { styled } from "@mui/material/styles";
import Loading from "../../Includes/Loading";
import { useParams } from "react-router-dom";
import Switch from "../../Includes/Switch";
import RegimenFiscal from "../../Lib/SAT/Componentes/RegimenFiscal";
import UsoCDFI from "../../Lib/SAT/Componentes/UsoCDFI";
import LoadingButton from "@mui/lab/LoadingButton";
import { guardarDatosFactura, getInfoFactura } from "../Funciones/Perfiles";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const DatosFacturacion = () => {
  const [open, setOpen] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [IsLoading, setIsLoading] = useState(true);
  const [IsGuardando, setIsGuardando] = useState(false);

  const { id } = useParams();
  const [Values, setValues] = useState({
    id: id ?? "0",
    rfc: "",
    razon_social: "",
    cp: "",
    regimen_fiscal: "",
    requiere_factura: false,
    cfdi: "",
  });

  const [Errores, setErrores] = useState({
    rfc: false,
    razon_social: false,
    cp: false,
    regimen_fiscal: false,
    requiere_factura: false,
    cfdi: false,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handlInputChange = ({ target }) => {
    const Name = target.name;
    const Value = target.value;
    let NewValue = {
      ...Values,
      //[Name]: Value.toUpperCase(),
      [Name]: Value,
    };

    if (Name == "requiere_factura") {
      NewValue = { ...NewValue, requiere_factura: Value };
    }

    setValues(NewValue);
    setErrores({
      ...Errores,
      [Name]: false,
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getInfoFactura()
      .then((resp) => {
        console.log(resp);
        setValues(resp.Values);
        setIsLoading(false);
      })
      .catch((resp) => {
        setIsLoading(false);
      });
  }, []);

  const GuardarDatosFactura = () => {
    setIsGuardando(true);
    guardarDatosFactura(Values, Errores, setErrores)
      .then((data) => {
        if (data.codigo == "200") {
          setIsGuardando(false);
          MySwal.fire({
            title: "Correcto",
            html: data.mensaje,
            icon: "success",
            confirmButtoColor: "#3ABE88",
            showConfirmButton: false,
            timer: 1700,
          }).then((result) => {});
        } else {
          setMensaje(data.mensaje);
          setOpen(true);
          setIsGuardando(false);
        }
      })
      .catch((data) => {
        setMensaje(data.mensaje);
        setOpen(true);
        setIsGuardando(false);
      });
  };

  const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
      "& .MuiSnackbar-root": {
        top: theme.spacing(15),
      },
    })
  );

  return (
    <>
      <StyledSnackbar
        direction="right"
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {mensaje}
        </Alert>
      </StyledSnackbar>
      {IsLoading ? (
        <Row className="px-3">
          <Col sm={12} className="p-3">
            <Loading />
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} className="p-3 ">
            <FormGroup className="align-items-end">
              <FormControlLabel
                control={
                  <Switch
                    checked={Values.requiere_factura}
                    name="requiere_factura"
                    error={Errores.requiere_factura}
                    onChange={(checked) => {
                      handlInputChange({
                        target: {
                          name: "requiere_factura",
                          value: checked,
                        },
                      });
                    }}
                    tituloActivo="No requiero factura"
                    tituloInactivo="Requiero factura"
                    on
                  />
                }
                label="Requiero Factura"
              />
            </FormGroup>
          </Col>
          <Row>
            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="rfc"
                label="RFC"
                variant="outlined"
                helperText={Errores.rfc ? "RFC invalido" : ""}
                value={Values.rfc}
                error={Errores.rfc}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="razon_social"
                label="Razón social"
                variant="outlined"
                helperText={Errores.razon_social ? "Razón social invalida" : ""}
                value={Values.razon_social}
                error={Errores.razon_social}
                onChange={handlInputChange}
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <RegimenFiscal
                Value={Values.regimen_fiscal}
                Error={Errores.regimen_fiscal}
                handlInputChange={handlInputChange}
                Name="regimen_fiscal"
              />
            </Col>

            <Col sm={12} md={6} className="p-3">
              <TextField
                fullWidth
                name="cp"
                inputProps={{ maxLength: 5 }}
                label="Código postal"
                variant="outlined"
                helperText={Errores.cp ? "Código postal invalido" : ""}
                value={Values.cp}
                error={Errores.cp}
                onChange={handlInputChange}
              />
            </Col>

            {/*
            <Col sm={12} md={6} className="p-3">
            <UsoCDFI
                Value={Values.cfdi}
                Error={Errores.cfdi}
                handlInputChange={handlInputChange}
              />
              </Col>*/}

            <Col sm={12} md={6} className="p-3">
              <LoadingButton
                loading={IsGuardando}
                loadingPosition="start"
                disabled={IsLoading}
                onClick={() => GuardarDatosFactura()}
                className="btn w-100 btn-michelle font-OxygenMedium  py-2 px-4 "
                variant="contained"
                xs={{ with: "100$" }}
              >
                <span className={IsGuardando ? "px-4" : "px-2"}>
                  Actualizar datos
                </span>
              </LoadingButton>
            </Col>
            <code>{/* <pre>{JSON.stringify(Values, null, 2)}</pre>  */}</code>
          </Row>
        </Row>
      )}
    </>
  );
};

export default DatosFacturacion;
