// src/components/CardRegistration.js
import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { LoadingButton } from "@mui/lab";
import { Col } from "react-bootstrap";
import {
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { guardarTarjeta } from "../../Funciones/Tarjetas";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);

const CardRegistration = ({ getTarjetas, SetshowModalTarjeta }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [nombre, setNombre] = useState("");
  const [nombreError, setNombreError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [IsGuardando, setIsGuardando] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const handleSubmit = async () => {
 
    setIsGuardando(true)
    if( nombre==undefined || nombre=="" || nombre==null )
  {

    MySwal.fire({
      title: "Alerta",
      html: "Ingresa el nombre del titular",
      icon: "warning",
      confirmButtoColor: "#C9A396",
    }).then((result) => {
      setIsGuardando(false);
    });
 return;
  }

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

 
    const { token, error } = await stripe.createToken(cardElement, {
      name: nombre 
    });


    console.log(token);

    if (error) { 
      MySwal.fire({
        title: "Error",
        html: error.message,
        icon: "warning",
        confirmButtoColor: "#C9A396",
      }).then((result) => {});
      setSuccess(false);
    } else {
      setSuccess(true);
      setError(" Token: " + token.id);
      guardarTarjeta(token.id, nombre, isDefault)
        .then((data) => {
          if (data.codigo == "200") {
            setIsGuardando(false);
            MySwal.fire({
              title: "Correcto",
              html: data.mensaje,
              icon: "success",
              confirmButtoColor: "#C9A396",
              showConfirmButton: false,
              timer: 1700,
            }).then((result) => {
              setIsGuardando(false);
              getTarjetas();
              SetshowModalTarjeta(false);
            });
          } else {
            MySwal.fire({
              title: "Error",
              html: data.mensaje,
              icon: "warning",
              confirmButtoColor: "#C9A396",
              showConfirmButton: false,
            }).then((result) => {

              setIsGuardando(false);
            });
          }
        })
        .catch((data) => {
          MySwal.fire({
            title: "Error",
            html: data.mensaje,
            icon: "warning",
            confirmButtoColor: "#C9A396",
          }).then((result) => {
            setIsGuardando(false);
          });
        });
    }
  };

  return (
    <div>
      <div className="form-group">
        <Col sm={12} className="p-2">
          <TextField
            fullWidth
            size="small"
            name="nombre"
            label="Nombre del titular"
            variant="standard"
            placeholder="Nombre como aparece en la tarjeta"
            value={nombre}
            error={nombreError}
            helperText={nombreError && "Ingresa el nombre del titular"}
            onChange={({ target }) => {
              setNombre(target.value);
            }}
          />
        </Col>

        <Col sm={12} className="p-2 mt-4 mb-4">
          <CardElement />
        </Col>

        <Col sm={12} className="p-2">
          <FormControlLabel
            onClick={() => {
              setIsDefault(!isDefault);
            }}
            className="mb-2"
            control={<Checkbox checked={isDefault} />}
            label={
              <Typography
                className="font-Oxygen"
                variant="body1"
                component="span"
                style={{ fontSize: "14px" }}
              >
                Tarjeta predeterminada
              </Typography>
            }
          />
        </Col>
      </div>
 

      <Col sm={12} className="p-3 mt-2">
        <LoadingButton
          loading={IsGuardando}
          loadingPosition="start"
          disabled={IsLoading}
          onClick={() => {
            // GuardarTarjeta()
            handleSubmit();
          }}
          className="btn shadow-none w-100 btn-michelle font-OxygenMedium  py-2 px-4 "
          variant="contained"
          xs={{ with: "100$" }}
        >
          <span className={IsGuardando ? "px-4 " : "px-2 font-OxygenMedium "}>
            {IsGuardando ? "Validando..." : "Agregar tarjeta"}
          </span>
        </LoadingButton>
      </Col>
    </div>
  );
};

export default CardRegistration;
