import { Typography } from '@mui/material'
import RangeSliderPrice from './RangeSliderPrice';

const FiltrosProductos = ({
    categoria,
    setCategoria,
    valuePrice,
    setValuePrice,
    widthRangeSlider = 200,
    absoluteRangeSlidePrecie = false
}) => {


    return (
        <div className="w-100  pe-2  sticky-top " style={{paddingTop:"5.1rem"}}>

            <div className='w-100' style={absoluteRangeSlidePrecie ? { height: 'calc(100% - 160px)' } : {}}>
                {
                    categoria && Array.isArray(categoria.lista) && (
                        categoria.lista.map((catego, indexCatego) => {
                            const classActive = catego.ID == categoria.id ? ' text-rosa ' : ''
                            return (
                                <Typography
                                    onClick={() => { setCategoria({ ...categoria, id: catego.ID }) }}
                                    className={"m-0 mt-0 font-Oxygen w-100 mb-4" + classActive}
                                    style={{ cursor: 'pointer', fontWeight: 'normal' }}>
                                    {catego.DESCRIPCCION}
                                </Typography>
                            )
                        })
                    )
                }
            </div>
            <div
                className={(absoluteRangeSlidePrecie ? '' : 'w-100')}
                style={absoluteRangeSlidePrecie ? { position: 'absolute', bottom: 0, width: 350, backgroundColor: '#FFF' } : {}}>
                <div class="LineRosa w-100" style={{ backgroundColor: 'lightgray' }} />

                <Typography className={"m-1 mt-0 font-OxygenBold w-100 mt-4 mb-3"} style={{ cursor: 'pointer' }}>
                    Precio
                </Typography>
                <RangeSliderPrice
                    className={absoluteRangeSlidePrecie ? 'mb-3' : ''}
                    width={widthRangeSlider}
                    value={valuePrice}
                    minValue={0}
                    maxValue={50000}
                    setValue={setValuePrice} />

            </div>


        </div>
    )
}

export default FiltrosProductos