import { Button, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Loading from "../../../Includes/Loading";
import { getInfoMonedero } from "../../Funciones/Orden";
import { formatMoneda } from "../../../Lib/Funciones Generales/Generales";
import ModalCreditos from "../../../MiPerfil/Componentes/Creditos/ModalCreditos";
import AddIcon from "@mui/icons-material/Add";

const Monedero = () => {
  const [IsLoading, setIsLoading] = useState(true);
  const [saldo, setSaldo] = useState(0);
  const [showModalCreditos, SetshowModalCreidtos] = useState(false);

  const getMonedero = () => {
    setIsLoading(true);
    getInfoMonedero()
      .then((resp) => {
        if (
          resp.data.saldo &&
          resp.data.saldo != undefined &&
          resp.data.saldo != null
        ) {
          setSaldo(resp.data.saldo);
          setIsLoading(false);
        }
      })
      .catch((resp) => {
        setSaldo(0);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getMonedero();
  }, []);

  return (
    <>
      {showModalCreditos ? (
        <ModalCreditos
          GetInfoCreditos={getMonedero}
          SetshowModalCreidtos={SetshowModalCreidtos}
        />
      ) : (
        ""
      )}

      <div className="pt-2 mb-3">
        <h6 className=" text-star mb-1 mt-3 fw-bolder font-PlayfairDisplay">
        Flower Wallet
        </h6>

        {IsLoading ? (
          <div className="col-12 my-5">
            <Loading />
          </div>
        ) : (
          <>
            <div
              className="px-3 py-2  mt-3 "
              style={{
                backgroundColor: "#F7F7F7",
                cursor: "pointer",
              }}
            >
              <div className=" w-100 mt-2 mt-md-0">
                <small>
                  <p className="font-OxygenBold my-1">Coins dísponibles</p>
                </small>
                <small>
                  <h3 className="font-Oxygen my-1">
                    {formatMoneda(parseFloat(saldo))}
                  </h3>
                </small>
              </div>
            </div>
          </>
        )}
        <div className="w-100 text-end mt-2">
          <Button
            onClick={() => SetshowModalCreidtos(true)}
            className="btn shadow-none  btn-michelle font-OxygenMedium  py-2 px-4 "
            variant="contained"
            style={{ max: "400px" }}
          >
            <span className={"px-2 font-OxygenMedium"}>Agregar MichCoins</span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default Monedero;
