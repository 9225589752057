import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Snackbar,
    TextField,
    Tooltip
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState, useEffect, useRef } from "react";
import { getInfoComprobante, guardarInfoComprobante } from "../../../Funciones/Pedidos";
import { Row, Col } from "react-bootstrap";
import { styled } from "@mui/material/styles";

import UploadIcon from '@mui/icons-material/Upload';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const MySwal = withReactContent(Swal);
const StyledSnackbar = styled((props) => <Snackbar {...props} />)(
    ({ theme }) => ({
        "& .MuiSnackbar-root": {
            top: theme.spacing(15),
        },
    })
);


const ModalComprobanteCargar = ({
    setComprobanteSeleccionado
    , comprobanteSeleccionado
    , pedidoSeleccionado
    , setShowDialogCargarComprobante
}) => {

    const fileRef = useRef(null)
    const [IsGuardando, setIsGuardando] = useState(false)
    const [mensaje, setMensaje] = useState("");
    const [openAlert, setOpenAlert] = useState(false);
    const [comprobante, setComprobante] = useState({
        id: comprobanteSeleccionado,
        id_venta: pedidoSeleccionado,
        img: "",
        nombre: "",
        comentario: "",
        actualizar_comprobante: false,
    });

    const GuardarComprobante = () => {
        new Swal({
            title: "¿Estás seguro de guardar el comprobante?",
            text: "Esta acción no se puede deshacer",
            icon: "warning",
            showDenyButton: true,
            denyButtonText: "No, cancelar",
            confirmButtonText: "Si, estoy seguro",
            confirmButtonColor: "#3ABE88",
            denyButtonColor: "#65748B",
            reverseButtons: true,
        }).then(function (isConfirm) {
            console.log(isConfirm.isConfirmed);
            if (isConfirm.isConfirmed) {
                guardarInfoComprobante(comprobante)
                    .then((data) => {
                        if (data.codigo == "200") {
                            MySwal.fire({
                                title: "Correcto",
                                html: data.respuesta.register,
                                icon: "success",
                                confirmButtoColor: "#3ABE88",
                                showConfirmButton: false,
                                timer: 1700,
                            }).then((result) => {
                                setShowDialogCargarComprobante(false);
                                if (window.fnConsultarComprobantes) {
                                    window.fnConsultarComprobantes()
                                }
                            });
                        } else {
                            setMensaje(data.mensaje);
                            setOpenAlert(true);
                        }
                    })
                    .catch((data) => {
                        setMensaje(data.mensaje);
                        setOpenAlert(true);
                    });
            }
        });
    }


    const handleImagen = ({ target }) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(target.files[0]);
        fileReader.onload = (e) => {
            setComprobante({
                ...comprobante,
                nombre: target.files[0].name,
                img: e.target.result,
                actualizar_comprobante: true
            })
        };
    };

    useEffect(() => {
        if (comprobanteSeleccionado != 0) {
            getInfoComprobante(comprobanteSeleccionado).then(resp => {
                setComprobante({
                    ...comprobante,
                    img: resp.URL,
                    nombre: resp.URL,
                    comentario: resp.COMENTARIO,
                    actualizar_comprobante: false,
                })
            }).catch(error => {
                console.log(error)
            })
        }
    }, [])

    return (

        <>
            <StyledSnackbar
                direction="right"
                open={openAlert}
                autoHideDuration={6000}
                onClose={() => setOpenAlert(false)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={() => setOpenAlert(false)} severity="error" sx={{ width: "100%" }}>
                    {mensaje}
                </Alert>
            </StyledSnackbar>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={true}
                PaperProps={{ elevation: 0 }}
            >
                <IconButton
                    aria-label="Close"
                    className="m-3 mt-2"
                    style={{ position: "absolute", right: "0%" }}
                    onClick={() => {
                        setShowDialogCargarComprobante(false);
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogTitle>
                    <h6 className="fw-bolder f-12 mt-2">Cargar comprobante de pago</h6>
                </DialogTitle>
                <DialogContent>

                    <Row className="mt-3">
                        <Col sm={12} className="p-3 d-flex justify-content-between align-items-center">

                            <TextField
                                style={{ zIndex: 1 }}
                                fullWidth
                                disabled={true}
                                maxRows={1}
                                value={comprobante.nombre}
                                multiline={true}
                                name="nombre"
                                label="Cargar comprobante"
                                placeholder="Cargar comprobante"
                                variant="outlined"
                                inputProps={{ maxLength: 300 }}
                            />

                            <Tooltip title="Cargar comprobante">
                                <IconButton
                                    style={{ position: 'absolute', zIndex: 10, right: 26, width: 56, height: 56 }}
                                    className="btn btn-Crexendo font-AvenirMedium p-2"
                                    onClick={() => fileRef.current.click()}>
                                    <UploadIcon style={{ color: "#FFF", fontSize: 30 }} />
                                </IconButton>
                            </Tooltip>

                        </Col>
                        {/* <Col sm={12} className="p-3">
                            <TextField
                                onChange={({ target }) => {
                                    setComprobante({ ...comprobante, comentario: target.value });
                                }}
                                value={comprobante.comentario}
                                fullWidth
                                multiline={true}
                                maxRows={3}
                                minRows={3}
                                size="small"
                                name="comprobante"
                                label="Observaciones"
                                placeholder="(Máx 300)"
                                variant="outlined"
                                inputProps={{ maxLength: 300 }}
                            />
                        </Col> */}

                        <Col sm={12} className="p-3">
                            <Button
                                onClick={GuardarComprobante}
                                loadingPosition="start"
                                className="btn btn-michelle  font-OxygenBold  py-2 px-4 "
                                variant="contained"
                            >
                                <span>Guardar</span>
                            </Button>

                        </Col>
                    </Row>




                    <input
                        className="d-none"
                        ref={fileRef}
                        accept=".jpg, .jpeg, .png, .webp"
                        type="file"
                        onChange={handleImagen} />

                </DialogContent>
            </Dialog>
        </>
    )
}

export default ModalComprobanteCargar